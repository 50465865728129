import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from '../../app-routing.module';
import { MaterialModule } from './../../material.module';
import { HttpInterceptorService } from '../../services/http-interceptor.service';
import { PipeModule } from "../pipes/pipe.module";
import { CustomReportDashboardComponent } from './components/custom-report-dashboard/custom-report-dashboard.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SideNavModule } from './components/side-nav/side-nav.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MultiHeaderTableComponent } from './utils/multi-header-table/multi-header-table.component';
import { LexusPoSnapshotComponent } from './reports/lexus-po-snapshot/lexus-po-snapshot.component';
import { GenericInputTableComponent } from './utils/generic-input-table/generic-input-table.component';
import { TCUVDealerAtGlanceComponent } from './reports/tcuv-dealer-at-glance/tcuv-dealer-at-glance.component';
import { GenericProfileTableComponent } from './utils/generic-profile-table/generic-profile-table.component';
import { LexusDealerAtGlanceComponent } from './reports/lexus-dealer-at-glance/lexus-dealer-at-glance.component';
import { PartsAndServiceDepartmentAnalysisComponent } from './reports/parts-and-service-department-analysis/parts-and-service-department-analysis.component';
import { LexusSalesAtGlanceComponent } from './reports/lexus-sales-at-glance/lexus-sales-at-glance.component';
import { PartsServiceSalesAnalysisComponent } from './reports/parts-service-sales-analysis/parts-service-sales-analysis.component';
import { ToyotaDealerAtGlanceComponent } from './reports/toyota-dealer-at-glance/toyota-dealer-at-glance.component';
import { PandSfinancialComponent } from './reports/P&S Financial/ps-financial.component';
import { NationalCompositeNewUsedDeptAnalysisComponent } from './reports/national-composite-new-used-dept-analysis/national-composite-new-used-dept-analysis.component';

@NgModule({
    declarations: [
    CustomReportDashboardComponent,
    SideNavComponent,
    MultiHeaderTableComponent,
    LexusPoSnapshotComponent,
    LexusDealerAtGlanceComponent,
    LexusSalesAtGlanceComponent,
    ToyotaDealerAtGlanceComponent,
    TCUVDealerAtGlanceComponent,
    GenericInputTableComponent,
    GenericProfileTableComponent,
    PartsAndServiceDepartmentAnalysisComponent,
    PartsServiceSalesAnalysisComponent,
    PandSfinancialComponent,
    NationalCompositeNewUsedDeptAnalysisComponent,
    ],
    imports: [
      CommonModule,
      AppRoutingModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      PipeModule,
      ScrollingModule,
      SideNavModule
  ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        }
    ],
    exports: [SideNavComponent]

})
export class CustomReportModule {}
