import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { UserProfileService } from '@toyota/dd365-platform-library';
import { catchError, forkJoin, map, of } from 'rxjs';
import { FIELDS_FOR_BulkUserRegistration } from 'src/app/constants/constants';
import { IEnvironmentConfig } from 'src/assets/config/config';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})

export class BulkUploadComponent {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  data: any = {};
  selectedFileName: string = '';
  summary: { user: string; message: string; isSuccess: boolean }[] = [];
  successUsers: string[] = [];
  message: string = '';
  isSuccess: boolean = false;
  errorUsers: { user: string; error: string }[] = [];
  hideButton: boolean = false;
  isMessageSet: boolean = false;
  missingFieldUsers: { userId: string, missingFields: string[] }[] = [];

  private readonly ALLOWED_ROLES = ['Workout_Enrolled', 'QuickSight-Reader-Role', 'DD_User_Admin'];
  private readonly DEFAULT_ROLE = 'Workout_Enrolled';

  constructor(private http: HttpClient, private readonly userProfileService: UserProfileService) {
    this.data = {};
    this.successUsers = [];
  }

  public fileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const csv: string = e.target.result;
      const lines = csv.split('\n');
      const result = [];
      const headers = this.parseCSVRow(lines[0]);
      const requiredProperties = ['userId', 'firstName', 'lastName', 'email', 'accessGroupName', 'roleName'];
      // Check if headers are missing columns
      if (headers.length < 5 && !this.isMessageSet) {
        alert('Empty file!');
        this.isSuccess = false;
        this.data = [];
        return;
      }
      // Parse CSV data
      const missingFieldMessage = [];
      let hasInvalidRole = false;
      const invalidRoleUsers = [];
      for (let i = 1; i < lines.length - 1; i++) {
        const row = this.parseCSVRow(lines[i]);
        const user = {
          ...(row[0] && { userId: row[0].trim() }),
          ...(row[1] && { firstName: row[1].trim() }),
          ...(row[2] && { lastName: row[2].trim() }),
          ...(row[3] && { email: row[3].trim() }),
          ...(row[4] && { accessGroupName: row[4].trim() }),
          ...(row[5] && { roleName: row[5].split(',').map(role => role.trim().replace(/['"]+/g, '')) })//split roles and remove quotes
        };
        const missingFields = this.validateRequiredFields(user, requiredProperties);
        if (missingFields.length > 0) {
          const missingFieldNames = missingFields.map(x => FIELDS_FOR_BulkUserRegistration[x]);
          const missingElement = this.message = ` UserId : ${user.userId} - Fields :${missingFieldNames}\n `;
          missingFieldMessage.push(missingElement);
        } else {
          const inValidRoles = user.roleName.filter(role => !this.ALLOWED_ROLES.includes(role));
          if (inValidRoles.length > 0) {
            invalidRoleUsers.push(user.userId);//add userId to invalidroleusers array
            hasInvalidRole = true;
          }
          if (!user.roleName.length) {
            user.roleName = [this.DEFAULT_ROLE];
          }
          result.push(user);//add valid user to result array
        }
        // else { result.push(user); }
      }
      if (missingFieldMessage.length > 0) {
        this.message = `Missing mandatory field(s)\n ${missingFieldMessage.join(', ')}`;
        this.isMessageSet = true;
      }
      if (hasInvalidRole) {
        alert(`Invalid or missing role(s) found for userId(s): ${invalidRoleUsers.join(', ')}. Default role 'Workout_Enrolled' has been assigned`);
      }
      this.data = result;
    };
    this.selectedFileName = target.files[0].name;
    reader.readAsText(target.files[0]);
  }

  private validateRequiredFields(obj: any, requiredProperties) {
    const missingFields = [];
    for (const prop of requiredProperties) {
      if (!obj.hasOwnProperty(prop)) {
        missingFields.push(prop);
      }
    }
    return missingFields;
  }

  // to parse single row of CSV file
  private parseCSVRow(row: string): string[] {
    const regex = /,(?=(?:(?:[^"]*"){2})*[^"]*$)/;//split the CSV row on commas that are not inside double quotes
    const fields = row.split(regex).map(field => field.trim());
    return fields.map(field => field.replace(/(^"|"$)/g, ''));
  }

  public bulkRegistration() {
    this.summary = [];
    this.successUsers = [];
    this.errorUsers = [];
    this.hideButton = true;
    //chek if there is data
    if (Object.keys(this.data).length === 0) {
      alert('Please upload a file before proceeding with Bulk Registration');
      return;
    }
    if (Object.keys(this.data).length > 0 && this.errorUsers.length === 0) {
      const apiEndPoint = IEnvironmentConfig[window.location.hostname].API_END_POINT + '/useradmin/user-permissions';
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.userProfileService.getProfile().token}`,
        'Dx-User-Agent': 'DXFW'
      });
      //array of observable for each user
      const observables = Object.keys(this.data).map(key => {
        const user = this.data[key];
        const requestBody = {
          bulkUserFlag: true,
          ...user//user data
        };
        return this.http.post(apiEndPoint, requestBody, { headers: headers }).pipe(
          map(() => ({ key, success: true })),//map response for success
          catchError(error => {
            const errorMessage = error?.error?.message || 'User(s) is/are not an Org User';
            return of({ key, success: false, error: errorMessage });
          })
        );
      });

      forkJoin(observables).subscribe(results => {// all observables
        results.forEach(result => {//each result
          if (result.success) {
            this.successUsers.push(result.key);//add to success if suucessfuls
          } else {
            this.errorUsers.push({ user: this.data[result.key].userId, error: (result as any).error });//add to error if failed
          }
        });
        this.updateSummary();
        this.hideButton = false;//enable button
      });
    } else {
      this.hideButton = false;// enable button if there is no data
    }
  }

  private updateSummary() {
    this.summary = [];
    const errorMap = new Map<string, string>();
    let headingAdd = false;
    //check if there are successful operations
    if (this.successUsers.length > 0) {
      //message with userid of successful users
      const successMessages = this.successUsers.map(userId => `${this.data[userId].userId}`).join(', ');
      this.summary.push({
        user: '',
        message: `Add user operations successful for user(s) with userId(s): ${successMessages}`,
        isSuccess: true
      });
    }
    if (this.errorUsers.length > 0) {
      this.errorUsers.forEach(error => {
        const errorMessage = `${error.error}`;
        //check if errormessage is already in map
        if (!errorMap.has(errorMessage)) {
          errorMap.set(errorMessage, error.user);
        } else {//add user id to message
          const existingId = errorMap.get(errorMessage);
          errorMap.set(errorMessage, `${existingId}, ${error.user}`);
        }
      });
      errorMap.forEach((userId, errorMessage) => {
        if (!headingAdd) {
          this.summary.push({
            user: '',
            message: `Encountered error while performing User Registration:`,
            isSuccess: false
          });
          headingAdd = true;
        }
        this.summary.push({
          user: '',
          message: `${errorMessage} with userId(s): ${userId}`,
          isSuccess: false
        });
      });
    }


  }

}