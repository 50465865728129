import { COMPANY_TYPE, HIERARCHY_TYPE } from "src/app/constants/constants";

export const MockCustomReportListResponseDEV = [
  {
    name: 'Lexus PO snapshot executive summary',
    id: 1
  },
  {
    name: 'Lexus Dealer At a Glance',
    id: 2
  },
  {
    name: 'TCUV Dealer At a Glance',
    id: 3
  },
  {
    name: 'Toyota Dealer At A Glance',
    id: 4
  },
  {
    name: 'Lexus Sales At A Glance Report',
    id: 5
  },
  {
    name: 'GST Toyota Dealer At A Glance',
    id: 6
  },
  {
    name: 'TTFO - Dealer at a Glance Report',
    id: 7
  },
  {
    name: 'Dealership Performance Summary',
    id: 8
  },
  {
    name: 'Parts Department',
    id: 9
  },
  {
    name: 'Service Dept Expense And Sales Analysis',
    id: 10
  }
];
 
export interface PeriodicElement {
  position: string;
  lyear20: string;
  lyear21: string;
  lyear22: string;
  lyear23: string,
  lchange23: string;
  year20: string;
  year21: string;
  year22: string;
  year23: string,
  change23: string;
}

export const ELEMENT_DATA: PeriodicElement[] = [
  { position: 'WESTERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'CENTRAL', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'EASTERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'SOUTHERN', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' },
  { position: 'NATIONAL', year20: '16.009', year21: '16.009', year22: '16.009', year23: '16.009', change23: '16.009', lyear20: '16.009', lyear21: '16.009', lyear22: '16.009', lyear23: '16.009', lchange23: '16.009' }
];

export const SingleProfileTable = [{
  "Dealership": "TOYOTA OF LONG BEACH",
  "Region": "Los Angeles",
  "Address": "4401 E. PACIFIC COAST HWY",
  "District": "LA District 1: Los Angeles/No. Orange County",
  "City": "LONG BEACH Market",
  "DistrictManager": "Becky Levy", 
}]

export const MockTopTenDealersResponsDEV = {

  "CERTIFIED-PRE-OWNED": [
    {
      "RANK": 1,
      "AREA": "southern",
      "DEALER": "JOHNSON LEXUS OF DURHAM",
      "YTD": 115,
    },
    {
      "RANK": 2,
      "AREA": "Eastern",
      "DEALER": "JOHNSON LEXUS OF RALEIGH ",
      "YTD": 97,
    }
  ],
  "TOTAL-LEXUS PRE-OWNED": [
    {
      "RANK": 1,
      "AREA": "SEWELL LEXUS ",
      "DEALER": "One T D1",
      "YTD": 11,
    },
    {
      "RANK": 2,
      "AREA": "Two",
      "DEALER": "One T D2",
      "YTD": 12,
    }
  ]
}

export const LexusDealerAtGlancePartsMockData = {
  body: [
    {
      "reportData": [
        {
          "(May 2019)": "Tires Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Tires Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Oil Sales Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Oil Sales Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Lexus Wipers Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Lexus Wipers Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Chemicals Penetration",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        },
        {
          "(May 2019)": "Chemicals Penetration Missed Opportunity in $",
          "OBJ": "11%",
          "NAT'L": "33.35",
          "MAY 2019": "33.35",
          "APR 2019": "33.26",
          "MAR 2019": "32.80",
          "FEB 2019": "31.50",
          "JAN 2019": "31.691",
          "DEC 2018": "31.691"
        }]
    }
  ],
  status: 200
}

export const LexusSalesAtGlanceMockData = {
  "Scores": {
    "tableData": [
      {
        "SCORE (JAN 01, 2020)": "Sales OSAT Score",
        "OBJ": "95.0",
        "JAN 20": "100.0",
        "DEC 19": "97.6",
        "NOV 19": "98.5",
        "OCT 19": "96.5",
        "SEP 19": "99.7",
        "AUG 19": "94.7",
        "DLR": "100.0",
        "DIST": "94.5",
        "AREA ": "95.2",
        "NAT'L": "95.0", 
      },
      {
        "SCORE (JAN 01, 2020)": "L/Cert OSAT Score",
        "OBJ": "89.0",
        "JAN 20": "n/a",
        "DEC 19": "100.0",
        "NOV 19": "97.5",
        "OCT 19": "100.0",
        "SEP 19": "90.0",
        "AUG 19": "100.0",
        "DLR": "n/a",
        "DIST": "90.5",
        "AREA ": "90.5",
        "NAT'L": "91.6"
      },
    ],
    "tableHeader": [
      {
        "GUEST SATISFACTION": '2',
        "DEALER 6-MONTH HISTORY": '6',
        "YEAR TO DATE": '4',
      }
    ],
  },
  "Scores_1": {
      "tableData": [
        {
          "RETAIL % OBJ (MAY 2023)": "L/Cert Retail Sales",
          "OBJ": "95.0",
          "MAY 23": "15.0",
          "APR 23": "85.0",
          "MAR 23": "113.3",
          "FEB 23": "180.0",
          "JAN 23": "140.0",
          "DEC 22": "223.0",
          "DLR": "92.0",
          "DIST": "100.8",
          "AREA ": "107.7",
          "NAT'L": "108.4"
        },
        {
          "RETAIL % OBJ (MAY 2023)": "New Retail Sales",
          "OBJ": "95.0",
          "MAY 23": "64.2",
          "APR 23": "55.7",
          "MAR 23": "66.0",
          "FEB 23": "71.7",
          "JAN 23": "78.4",
          "DEC 22": "72.8",
          "DLR": "66.4",
          "DIST": "100.5",
          "AREA ": "102.1",
          "NAT'L": "106.2"
        }
    ],
    "tableHeader": [
      {
        "SALES-VOLUME": '2',
        "DEALER 6-MONTH HISTORY": '6',
        "YEAR TO DATE": '4',
      }
    ]
  },
  "Sales": {
    "tableData": [
      {
        " ": "Dealer New Vehicle Sales",
        "MTD OBJ": "95",
        "MAY 23": "61",
        "APR 23": "63",
        "MAR 23": "59",
        "FEB 23": "51",
        "JAN 23": "61",
        "DEC 22": "67",
        "%MTD OBJ": "64.20",
        "SALES AS % AREA": "101.7",
        "YTD": "100.5",
        "LYTD": "103.0",
        "  ": '-122' 
      },
      {
        " ": "Dealer TTL PO Sales",
        "MTD OBJ": "20",
        "MAY 23": "3",
        "APR 23": "17",
        "MAR 23": "17",
        "FEB 23": "18",
        "JAN 23": "14",
        "DEC 22": "29",
        "%MTD OBJ": "15.0",
        "SALES AS % AREA": "101.7",
        "YTD": "100.5",
        "LYTD": "103.0",
        "  ": '-122' 
      },
      {
        " ": "Dealer CPO Sales",
        "MTD OBJ": "20",
        "MAY 23": "3",
        "APR 23": "17",
        "MAR 23": "17",
        "FEB 23": "18",
        "JAN 23": "14",
        "DEC 22": "29",
        "%MTD OBJ": "15.0",
        "SALES AS % AREA": "101.7",
        "YTD": "100.5",
        "LYTD": "103.0",
        "  ": '-16'
      },
    ],
    "tableHeader": [
      {
        "SALES(MAY 2023)": '2',
        "DEALER 6-MONTH HISTORY": '6',
        "MTD": '2',
        "YTD ": '2',
        "UNITS +/-ELITE": ''
      }
    ]
  },
  "SalesByModel": {
    "tableData": [
      {
        " ": "Total All Series",
        "2023   ": "61",
        
        "2022   ": "68",
        "%CHANGE   ": "-10.3%",
        "DLR   ": "55.9",
        "DIST   ": "78.5",
        "AREA   ": "77.8",
        "DLR    ": "21.4",
        "DIST    ": "6.6",
        "AREA    ": "6.9",
        "2023    ": "292",
        "2022    ": "343",
        "%CHANGE    ": "-16.1%",
        
 
      },
      {
        " ": "IS",
        "2023   ": "5",
        "2022   ": "10",
        "%CHANGE   ": "-50.0%",
        "DLR   ": "55.5",
        "DIST   ": "79.5",
        "AREA   ": "75.9",
        "DLR    ": "22.7",
        "DIST    ": "6.4",
        "AREA    ": "7.7",
        "2023    ": "21",
        "2022    ": "38",
        "%CHANGE    ": "-44.7%",
      },
      {
        " ": "RC",
        "2023   ": 0,

        "2022   ": "1",
        "%CHANGE   ": "-100.0%",
        "DLR   ": "0.0",
        "DIST   ": "46.2",
        "AREA   ": "36.8",
        "DLR    ": "0.0",
        "DIST    ": "25.5",
        "AREA    ": "47.5",
        "2023    ": 0,
        "2022    ": "2",
        "%CHANGE    ": "-100.0%",
        
 
      },
      {
        " ": "RC-F",
        "2023   ": 0,
        "2022   ": 0,
        "%CHANGE   ": "0.0%",
        "DLR   ": "0.0",
        "DIST   ": "50.0",
        "AREA   ": "20.0",
        "DLR    ": "0.0",
        "DIST    ": "61.2",
        "AREA    ": 1,
        "2023    ": 0,
        "2022    ": "2",
        "%CHANGE    ": "0.0%",
      },
    ],
    "tableHeader": [
      {
        "SALES BY MODEL (MAY 2023)": '1',
        "MONTH END SALES": '3',
        "MONTH END SALES TO AVAILABILITY": '3',
        "MONTH END DAYS SUPPLY": '3',
        " YTD SALES": '3'
      }
    ]
  },
  "marketShare": {
    "tableData": [
      {
        " ": "Total Luxury Car",
        "DLR     ": "10.0",
        "METRO": "0.0",
        "AREA ": "7.6",
        "DLR  ": "9.2",
        "METRO   ": "0.0",
        "AREA   ": "7.3",
        "DLR    ": "-1.3",
        "METRO    ": "-9.5",
        "AREA    ": "-1.6",
        "    ": "-",
        "     ": "TES",
        "  ": "-179"
      },
      {
        " ": "Total Luxury SUV",
        "DLR     ": "12.4",
        "METRO": "0.0%",
        "AREA ": "10.7",
        "DLR  ": "12.6",
        "METRO   ": "0.0",
        "AREA   ": "11.1",
        "DLR    ": "-1.1",
        "METRO    ": "-12.4",
        "AREA    ": "-0.7",
        "    ": "-",
        "     ": "TES",
        "  ": "-171"
      },
      {
        " ": "Total",
        "DLR     ": "11.5",
        "METRO": "0.0%",
        "AREA ": "9.6",
        "DLR  ": "11.4",
        "METRO   ": "0.0",
        "AREA   ": "9.8",
        "DLR    ": "-1.0",
        "METRO    ": "-11.2",
        "AREA    ": "-0.9",
        "    ": "105.2",
        "     ": "TES",
        "  ": "-350"
      },
    ],
    "tableHeader": [
      {
        "MARKET SHARE (JUL 2023)": '1',
        "ROLLING 12 MONTHS": '3',
        "YTD": '3',
        "YTD %CHANGE 2022": '3',
        "SALES EFFICIENCY": '',
        "MARKET LEADER": '',
        "UNITS +/- LEADER": ''
      }
    ]
  },
  "grossProfit": {
    "tableData": [
      {
        "    ": "Total All Series",
      "DLR": "$4,286",
        "DIST AVG": "$2,527",
        "AREA AVG": "$1,936",
        "DLR ": "$5,644",
        "DIST AVG ": "$2,831",
        "AREA AVG ": "$2,267",
        "DLR  ": "$7,616",
        "DIST AVG  ": "$5,294",
        "AREA AVG  ": "$4,205",
        "DLR   ": "$8,712",
        "DIST AVG    ": "$5,436",
        "AREA AVG    ": "$4,532"
      },
      {
        "    ": "IS",
        "DLR": "$2,670",
        "DIST AVG": "$1,318",
        "AREA AVG": "$1,172",
        "DLR ": "$3,241",
        "DIST AVG ": "$1,242",
        "AREA AVG ": "$1,171",
        "DLR  ": "$2,670",
        "DIST AVG  ": "$2,115",
        "AREA AVG  ": "$1,531",
        "DLR   ": "$3,306",
        "DIST AVG    ": "$1,940",
        "AREA AVG    ": "$1,515"
        
      },
      {
        "    ": "RC",
        "DLR": "$0",
        "DIST AVG": "$3,607",
        "AREA AVG": "$1,339",
        "DLR ": "$0",
        "DIST AVG ": "$1,723",
        "AREA AVG ": "$1,206",
        "DLR  ": "$0",
        "DIST AVG  ": "$4,018",
        "AREA AVG  ": "$1,654",
        "DLR   ": "$0",
        "DIST AVG    ": "$2,172",
        "AREA AVG    ": "$1,492"
      },
    ],
    "tableHeader": [
      {
        "GROSS PROFIT (MAY 2023)": 15
      }
    ],
    "tableSubHeader":
     [
      {
        "        ": 1,
        "GROSS PROFIT BEFORE INCENTIVES $PUR/L": 6,
        "GROSS PROFIT AFTER INCENTIVES $PUR/L": 6,
      },
    ],
      "tableSubHeader2":
       [
      {
        "             ": 1,
        "MTD": 3,
        "YTD": 3,
        "MTD ":3,
        "YTD ":3,
      }
    ],
  }
};
 
export const PartsDepartmentAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "4143",
          "DEALER_NAME": "City Toyota",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
 
              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9659",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Cust Mech Sls",
              "DLR_CY": "6205260.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "4342484.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "42.89",
              "DIST_CY": "3218154",
              "DIST_GP_CY": null,
              "DIST_PY": "2786534",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "15.48",
              "RGN_CY": "2901781",
              "RGN_GP_CY": null,
              "RGN_PY": "2591865",
              "RGN_GP_PY": null,
              "REGION_DIFF": "11.95",
              "NTNL_CY": "2192125",
              "NTNL_GP_CY": null,
              "NTNL_PY": "2007870",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "9.17"
            },
            {
 
              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710S",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Body Shop Sls",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "0.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "0.00",
              "DIST_CY": "152028",
              "DIST_GP_CY": null,
              "DIST_PY": "135914",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "11.85",
              "RGN_CY": "131857",
              "RGN_GP_CY": null,
              "RGN_PY": "114463",
              "RGN_GP_PY": null,
              "REGION_DIFF": "15.19",
              "NTNL_CY": "364408",
              "NTNL_GP_CY": null,
              "NTNL_PY": "336516",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "8.28"
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "Gross Profit $/%",
          "sectionData": [
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9660",
              "ACCT_NO_GP": "B8046",
              "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
              "DLR_CY": "1877303.0000",
              "DLR_GP_CY": "38.0",
              "DLR_PY": "1316164.0000",
              "DLR_GP_PY": "38.1",
              "DEALER_DIFF": "42.63",
              "DIST_CY": "1108915",
              "DIST_GP_CY": "42.2",
              "DIST_PY": "959660",
              "DIST_GP_PY": "42.0",
              "DISTRICT_DIFF": "15.55",
              "RGN_CY": "987690",
              "RGN_GP_CY": "41.5",
              "RGN_PY": "871637",
              "RGN_GP_PY": "40.8",
              "REGION_DIFF": "13.31",
              "NTNL_CY": "679932",
              "NTNL_GP_CY": "0.4",
              "NTNL_PY": "622945",
              "NTNL_GP_PY": "0.4",
              "NATIONAL_DIFF": "9.14"
            },
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710T",
              "ACCT_NO_GP": "B8049",
              "acct_name": "Body Shop P & A  GP",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": "0.0",
              "DLR_PY": "0.0000",
              "DLR_GP_PY": "0.0",
              "DEALER_DIFF": "0.00",
              "DIST_CY": "54653",
              "DIST_GP_CY": "35.9",
              "DIST_PY": "49471",
              "DIST_GP_PY": "36.4",
              "DISTRICT_DIFF": "10.47",
              "RGN_CY": "44929",
              "RGN_GP_CY": "34.1",
              "RGN_PY": "39523",
              "RGN_GP_PY": "34.5",
              "REGION_DIFF": "13.67",
              "NTNL_CY": "111221",
              "NTNL_GP_CY": "0.3",
              "NTNL_PY": "103238",
              "NTNL_GP_PY": "0.3",
              "NATIONAL_DIFF": "7.73"
            },
            {
              "sectionNo": 3,
              "sectionTitle": "Inventory",
              "sectionData": [
                {
                  "Line": "1",
                  "Gross_profit": "P & A Inventory",
                  "YTD": "111",
                  "GP_Sales": "2.2%",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "-100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                },
                {
                  "Line": "2",
                  "Gross_profit": "Inv.- P and A - Other",
                  "YTD": "111",
                  "GP_Sales": "NULL",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                }
              ]
            }
          ]
 
        }
      ]
    }
  ]
}

export const mockforkeyInsightTdaag = [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "4143",
          "DEALER_NAME": "City Toyota",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
 
              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9659",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Cust Mech Sls",
              "DLR_CY": "6205260.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "4342484.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "42.89",
              "DIST_CY": "3218154",
              "DIST_GP_CY": null,
              "DIST_PY": "2786534",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "15.48",
              "RGN_CY": "2901781",
              "RGN_GP_CY": null,
              "RGN_PY": "2591865",
              "RGN_GP_PY": null,
              "REGION_DIFF": "11.95",
              "NTNL_CY": "2192125",
              "NTNL_GP_CY": null,
              "NTNL_PY": "2007870",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "9.17"
            },
            {
 
              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710S",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Body Shop Sls",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "0.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "0.00",
              "DIST_CY": "152028",
              "DIST_GP_CY": null,
              "DIST_PY": "135914",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "11.85",
              "RGN_CY": "131857",
              "RGN_GP_CY": null,
              "RGN_PY": "114463",
              "RGN_GP_PY": null,
              "REGION_DIFF": "15.19",
              "NTNL_CY": "364408",
              "NTNL_GP_CY": null,
              "NTNL_PY": "336516",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "8.28"
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "Gross Profit $/%",
          "sectionData": [
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9660",
              "ACCT_NO_GP": "B8046",
              "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
              "DLR_CY": "1877303.0000",
              "DLR_GP_CY": "38.0",
              "DLR_PY": "1316164.0000",
              "DLR_GP_PY": "38.1",
              "DEALER_DIFF": "42.63",
              "DIST_CY": "1108915",
              "DIST_GP_CY": "42.2",
              "DIST_PY": "959660",
              "DIST_GP_PY": "42.0",
              "DISTRICT_DIFF": "15.55",
              "RGN_CY": "987690",
              "RGN_GP_CY": "41.5",
              "RGN_PY": "871637",
              "RGN_GP_PY": "40.8",
              "REGION_DIFF": "13.31",
              "NTNL_CY": "679932",
              "NTNL_GP_CY": "0.4",
              "NTNL_PY": "622945",
              "NTNL_GP_PY": "0.4",
              "NATIONAL_DIFF": "9.14"
            },
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710T",
              "ACCT_NO_GP": "B8049",
              "acct_name": "Body Shop P & A  GP",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": "0.0",
              "DLR_PY": "0.0000",
              "DLR_GP_PY": "0.0",
              "DEALER_DIFF": "0.00",
              "DIST_CY": "54653",
              "DIST_GP_CY": "35.9",
              "DIST_PY": "49471",
              "DIST_GP_PY": "36.4",
              "DISTRICT_DIFF": "10.47",
              "RGN_CY": "44929",
              "RGN_GP_CY": "34.1",
              "RGN_PY": "39523",
              "RGN_GP_PY": "34.5",
              "REGION_DIFF": "13.67",
              "NTNL_CY": "111221",
              "NTNL_GP_CY": "0.3",
              "NTNL_PY": "103238",
              "NTNL_GP_PY": "0.3",
              "NATIONAL_DIFF": "7.73"
            },
            {
              "sectionNo": 3,
              "sectionTitle": "Inventory",
              "sectionData": [
                {
                  "Line": "1",
                  "Gross_profit": "P & A Inventory",
                  "YTD": "111",
                  "GP_Sales": "2.2%",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "-100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                },
                {
                  "Line": "2",
                  "Gross_profit": "Inv.- P and A - Other",
                  "YTD": "111",
                  "GP_Sales": "NULL",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  // "IS_USER_INPUT": false
                }
              ]
            }
          ]
 
        }
      ]
    }
  ]
export const ServiceDeptAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "4143",
          "DEALER_NAME": "City Toyota",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
 
              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9659",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Cust Mech Sls",
              "DLR_CY": "6205260.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "4342484.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "42.89",
              "DIST_CY": "3218154",
              "DIST_GP_CY": null,
              "DIST_PY": "2786534",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "15.48",
              "RGN_CY": "2901781",
              "RGN_GP_CY": null,
              "RGN_PY": "2591865",
              "RGN_GP_PY": null,
              "REGION_DIFF": "11.95",
              "NTNL_CY": "2192125",
              "NTNL_GP_CY": null,
              "NTNL_PY": "2007870",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "9.17",
              "GUIDES": "<20%"
            },
            {
 
              "IS_USER_INPUT": false,
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710S",
              "ACCT_NO_GP": null,
              "acct_name": "P&A Body Shop Sls",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": null,
              "DLR_PY": "0.0000",
              "DLR_GP_PY": null,
              "DEALER_DIFF": "0.00",
              "DIST_CY": "152028",
              "DIST_GP_CY": null,
              "DIST_PY": "135914",
              "DIST_GP_PY": null,
              "DISTRICT_DIFF": "11.85",
              "RGN_CY": "131857",
              "RGN_GP_CY": null,
              "RGN_PY": "114463",
              "RGN_GP_PY": null,
              "REGION_DIFF": "15.19",
              "NTNL_CY": "364408",
              "NTNL_GP_CY": null,
              "NTNL_PY": "336516",
              "NTNL_GP_PY": null,
              "NATIONAL_DIFF": "8.28",
              "GUIDES": "<20%"
            }
          ]
        },
        {
          "SECTIONID": "2",
          "SECTIONTITLE": "Gross Profit $/%",
          "sectionData": [
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 1,
              "ACCT_NO": "B9660",
              "ACCT_NO_GP": "B8046",
              "acct_name": "Total  P & A Mechanical/TC/TC+ GP",
              "DLR_CY": "1877303.0000",
              "DLR_GP_CY": "38.0",
              "DLR_PY": "1316164.0000",
              "DLR_GP_PY": "38.1",
              "DEALER_DIFF": "42.63",
              "DIST_CY": "1108915",
              "DIST_GP_CY": "42.2",
              "DIST_PY": "959660",
              "DIST_GP_PY": "42.0",
              "DISTRICT_DIFF": "15.55",
              "RGN_CY": "987690",
              "RGN_GP_CY": "41.5",
              "RGN_PY": "871637",
              "RGN_GP_PY": "40.8",
              "REGION_DIFF": "13.31",
              "NTNL_CY": "679932",
              "NTNL_GP_CY": "0.4",
              "NTNL_PY": "622945",
              "NTNL_GP_PY": "0.4",
              "NATIONAL_DIFF": "9.14",
              "GUIDES": "<20%"
            },
            {
              "IS_USER_INPUT": false,
              "SECTIONID": 2,
              "SECTIONTITLE": "Gross Profit $/%",
              "SECTIONLINENO": 2,
              "ACCT_NO": "4710T",
              "ACCT_NO_GP": "B8049",
              "acct_name": "Body Shop P & A  GP",
              "DLR_CY": "0.0000",
              "DLR_GP_CY": "0.0",
              "DLR_PY": "0.0000",
              "DLR_GP_PY": "0.0",
              "DEALER_DIFF": "0.00",
              "DIST_CY": "54653",
              "DIST_GP_CY": "35.9",
              "DIST_PY": "49471",
              "DIST_GP_PY": "36.4",
              "DISTRICT_DIFF": "10.47",
              "RGN_CY": "44929",
              "RGN_GP_CY": "34.1",
              "RGN_PY": "39523",
              "RGN_GP_PY": "34.5",
              "REGION_DIFF": "13.67",
              "NTNL_CY": "111221",
              "NTNL_GP_CY": "0.3",
              "NTNL_PY": "103238",
              "NTNL_GP_PY": "0.3",
              "NATIONAL_DIFF": "7.73",
              "GUIDES": "<20%"
            },
            {
              "sectionNo": 3,
              "sectionTitle": "Inventory",
              "sectionData": [
                {
                  "Line": "1",
                  "Gross_profit": "P & A Inventory",
                  "YTD": "111",
                  "GP_Sales": "2.2%",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "-100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  "GUIDES": "<20%"
                },
                {
                  "Line": "2",
                  "Gross_profit": "Inv.- P and A - Other",
                  "YTD": "111",
                  "GP_Sales": "NULL",
                  "Prior_YTD": "342102",
                  "GP_Sales1": "42.4%",
                  "+/-%": "100",
                  "K_YTD": "111",
                  "K_GP_Sales": "0.0%",
                  "K_Prior_YTD": "720831",
                  "K_GP_Sales1": "38.7%",
                  "K_+/-%": "-100",
                  "KA_YTD": "111",
                  "KA_GP_Sale": "0.0%",
                  "KA_Prior_YTD": "720831",
                  "KA_GP_Sale1": "37.8%",
                  "KA_+/-%": "-100",
                  "N_YTD": "111",
                  "N_GP_Sale": "0.4%",
                  "N_Prior_YTD": "720831",
                  "N_GP_Sale1": "0.4%",
                  "N_+/-%": "-100",
                  "GUIDES": "<20%"
                }
              ]
            }
          ]
 
        }
      ]
    }
  ]
}

export const SalesAndPartsAnalysisMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "34",
              "SELECTED_MONTH": "29",
              "YTD": "265",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "65",
              "SELECTED_MONTH": "71",
              "YTD": "456",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Customer Pay Labor Sales",
              "CLASS_AVG": "98",
              "SELECTED_MONTH": "100",
              "YTD": "722",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "PROFITABILITY",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "34",
              "SELECTED_MONTH": "60",
              "YTD": "306",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "1",
              "SELECTED_MONTH": "0",
              "YTD": "0",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "PROFITABILITY",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Cust Mech Labor GP",
              "CLASS_AVG": "(18.5%)",
              "SELECTED_MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS_GUIDE": null,
              "NCM_GUIDE": null
            }
          ]
        },
      ]
    }
  ]
}

export const mockTableHeader = [
  {
    "Parts": '2',
    "MTD(AVERAGE DEALER)": '1',
    "DEALER 6-MONTH HISTORY": '6'
  }
]
 


export const SalesAnalysisNewVehicleMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Retail Total Sales",
              "CLASS AVG": "34",
              "SELECTED MONTH": "29",
              "YTD": "265",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "Total New Trk Toy Retail/Lease",
              "CLASS AVG": "65",
              "SELECTED MONTH": "71",
              "YTD": "456",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "New Toyota Vehicle Units",
              "CLASS AVG": "98",
              "SELECTED MONTH": "100",
              "YTD": "722",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Retail Total Sales",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Fleet - Total Sales",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "Toyota Car Sales YTD% Change",
              "CLASS AVG": "(18.5%)",
              "SELECTED MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
      ]
    }
  ]
}

export const PsFinancialMockData = {
  "PSFinancial":
   {
    "tableData": [
      {
        " ": "P & A Customer Mechanical Sales",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "Total P & A MRO + Exp Lube",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "Total  P & A Mechanical/TC/TC+ GP",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "P & A Customer Mechanical GP % of Sales",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "P & A Mechanical RO Tickets",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
      {
        " ": "Mechanical Sales % of Total",
        "2024 ": "$3,388,930	",
        "2023 ": "$2,804,773 ",
        "Change ": "	",
        "2024  ": "$25,664,093",
        "2023   ": "$27,712,525",
        "CHANGE   ": " ",
      },
     
    ],
    "tableHeader": [
      {
        "CURRENT MONTH": '4',
        "YEAR TO DATE": '3',
      }
    ]
  }

}

export const SalesAnalysisUsedVehicleMockData = {
  body: [
    {
      "reportHeaderData": [
        {
          "COMPANY_CODE": "14",
          "COMPANY_NAME": "Toyota",
          "COMPANY": "14 - Toyota",
          "REGION_CODE": "120",
          "REGION": "120 - San Francisco",
          "REGION_NAME": "San Francisco",
          "DISTRICT_CODE": "120D",
          "DISTRICT_NAME": "District 4 - SF Metro/North Bay",
          "DEALER_CODE": "04285",
          "DEALER_NAME": "ENVISION TOYOTA/NORWALK",
          "DEALER": "04143 - City Toyota",
          "NATIONAL": "NATIONAL"
        }
      ],
      "reportData": [
        {
          "SECTIONID": 1,
          "SECTIONTITLE": "Sales",
          "sectionData": [
            {
              "SECTIONID": 1,
              "SECTIONTITLE": "Sales",
              "SECTIONLINENO": 1,
              "ACCT_NAME": "TTL Toyota/Trac Used Veh R/L Aft Recon Units",
              "CLASS AVG": "34",
              "SELECTED MONTH": "29",
              "YTD": "265",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 2,
          "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
          "sectionData": [
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "TTL Toyota/Trac Used Veh R/L Aft Recon Units",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 2,
              "SECTIONTITLE": "SALES - SAME MONTH PRIOR YEAR",
              "SECTIONLINENO": 2,
              "ACCT_NAME": "%Change-Tot UsedRetail & Lease",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        },
        {
          "SECTIONID": 3,
          "SECTIONTITLE": "ASSET MANAGEMENT",
          "sectionData": [
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used Veh Inventory-$",
              "CLASS AVG": "34",
              "SELECTED MONTH": "60",
              "YTD": "306",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Other Used Vehicles-Inv",
              "CLASS AVG": "1",
              "SELECTED MONTH": "0",
              "YTD": "0",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            },
            {
              "SECTIONID": 3,
              "SECTIONTITLE": "ASSET MANAGEMENT",
              "SECTIONLINENO": 3,
              "ACCT_NAME": "Toyota Car Sales YTD% Change",
              "CLASS AVG": "(18.5%)",
              "SELECTED MONTH": "(51.7%)",
              "YTD": "(13.4%)",
              "TMS GUIDE": null,
              "NCM GUIDE": null
            }
          ]
        }
      ]
    }
  ]
}

export const customSearchObject = [
  { key: 'date', value: '12/01/2023' },
  { key: 'dealer', value: '202202' },
  { key: 'company', value: 'lexus' }
]
 
export const mockCompanyDealers = [
  { id: 1, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'HOOMAN toyota of long beach' },
  { id: 2, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'Cabe toyota of long beach' },
  { id: 3, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'NORWALK toyota of long beach' },
  { id: 4, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'BEACH CITIES toyota of long beach' },
  { id: 5, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'Toyota of long beach' },
  { id: 6, region: 'LA', district: 'A', dealer_code: '04011', dealername: 'PENSKY toyota of long beach' }
 
];
 
export const HrcyMemMock = [
  { dealer_code: 310, dealer_name: 'Western' },
  { dealer_code: 320, dealer_name: 'Central' },
  { dealer_code: 330, dealer_name: 'Estern' },
  { dealer_code: 340, dealer_name: 'Southern' },
]

export const HrcyMock = [
  { name: 'Dealer', id: HIERARCHY_TYPE.DEALER },
  { name: 'District', id: HIERARCHY_TYPE.DISTRICT },
  { name: 'Region', id: HIERARCHY_TYPE.REGION },
  { name: 'National', id: HIERARCHY_TYPE.NATIONAL },
]


 
export const CompanyTypeMock = [
  { name: 'Toyota', id: COMPANY_TYPE.TOYOTA },
  { name: 'Lexus', id: COMPANY_TYPE.LEXUS }
]
 
 

export const financemock = [
  {
      "1": "TCUV Sales",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    },
    {
      "1": "TCUV TRAC Sls",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    },
    {
      "1": "TCUV TRAC Sls",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    },
    {
      "1": "Total TCUV Sales",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    },
    {
      "1": "Toyota Used",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    },
    {
      "1": "Non Toyota Used",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    },
    {
      "1": "Total Used Sales",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    },
    {
      "1": "TCUV to Ttl Used",
      "2000": "37.0",
      "2001": "33.35",
      "% change": "33.35",
      "2002": "37.0",
      "2003": "33.35",
      "DISTRICT": "33.35",
      "REGIONS": "31.691",
      "NATIONAL": "31.691"
    }
]
