import { Injectable } from '@angular/core';
import { map, Observable, timeout } from 'rxjs';
import { IReportList } from '../interfaces/workout-reports.interface';
import { WorkoutReportsCoreService } from './workout-reports-core.service';
import { IActivityParams, IConfig } from '../../../interface/common.interface';
import { UserProfileService } from '@toyota/dd365-platform-library';

@Injectable({
  providedIn: 'root'
})
export class WorkoutReportsApiService {

  public selectedReportInfo: IReportList;

  constructor(
    private readonly workoutReportsCoreService: WorkoutReportsCoreService,
    private readonly userProfileService: UserProfileService,
  ) { }

  getReportList(env: IConfig, menuId: number): Observable<any> {
    const userId = this.userProfileService.getProfile().userId;
    return this.workoutReportsCoreService.getMockReportList(env, userId, menuId)
    .pipe(timeout(20000))
    .pipe(map(resp => resp));
  }

  public setReportInfo(reportInfo: IReportList): void {
    localStorage.setItem('selected-obj', JSON.stringify(reportInfo));    
  }

  public getReportInfo(): any {
    return localStorage.getItem('selected-obj');
  }

  public getDashboardUrl(dashboardId: string): Observable<any> {
    const emailId = this.userProfileService.getProfile().email;
    return this.workoutReportsCoreService.getDashboardUrl(emailId, dashboardId)
    .pipe(timeout(20000))
    .pipe(map(resp => resp));
  }

  public addActivityLog(activityParams: IActivityParams): Observable<any> {
    return this.workoutReportsCoreService.addActivityLogs(activityParams)
    .pipe(timeout(20000))
    .pipe(map(resp => resp));
  }

  public getIPAddress(): Observable<any> {
    return this.workoutReportsCoreService.getIPAddress()
    .pipe(timeout(2000))
    .pipe(map(resp => resp));
  }
}
