export const ReportListTableDef = [
  {
    columnName: 'Name',
    searchFieldName: 'name',
    searchFieldType: 'string',
    isCustomReport: true
  },
  {
    columnName: 'Department',
    searchFieldName: 'department',
    searchFieldType: 'multiselect',
    searchFieldOptions: [],
    isCustomReport: 'boolean'
  },
  {
    columnName: 'Frequency',
    searchFieldName: 'frequency',
    searchFieldType: 'select',
    searchFieldOptions: [{name: 'Daily', value: 'daily'}, {name: 'Monthly', value: 'monthly'}],
    isCustomReport: 'boolean'
  },
  {
    columnName: 'Data As Of',
    searchFieldName: 'dataAsOf',
    searchFieldType: 'calendar',
    isCustomReport: 'boolean'
  },  
  {
    columnName: 'Date Of Last Use',
    searchFieldName: 'dateLastUse',
    searchFieldType: 'calendar',
    isCustomReport: 'boolean'
  }
]