<div *ngIf="nationalCompositeNewUsedDeptAnalysisjsonObj?.datasource">
  <div class="dxfw-table-heading" *ngIf="nationalCompositeNewUsedDeptAnalysisjsonObj?.datasource?.length > 0">{{reportTitleToDisplay}}</div>
  <div [ngClass]="nationalCompositeNewUsedDeptAnalysisjsonObj?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
    <div *ngIf="nationalCompositeNewUsedDeptAnalysisjsonObj?.datasource?.length > 0 ">
      <img [src] = "imgPath" alt ="image">
    </div>
    <p *ngIf ="nationalCompositeNewUsedDeptAnalysisjsonObj?.datasource?.length>0" class="header">YEAR-TO-DATE AVERAGES</p>
    <div *ngFor="let currdataSource of nationalCompositeNewUsedDeptAnalysisjsonObj?.datasource; let  i = index">
      <div class="dfxw-part-table">
        <app-multi-header-table [tableIndex]="i" [multiHeaderTableData]='currdataSource' [reportId]="reportId">
        </app-multi-header-table>
      </div>
    </div>
  </div>
  <div class="dxfw-no-data" *ngIf="nationalCompositeNewUsedDeptAnalysisjsonObj?.datasource && selectedFilteredValues?.length != 0">{{noDataToDisplayMsg}}</div>
  <div class="dxfw-no-data" *ngIf="selectedFilteredValues?.length === 0">Please select the filters...</div>
</div>