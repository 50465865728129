import { Component, Input, OnInit } from '@angular/core';
import { TCUV_DEALER_AT_GLANCE_PROFILE_DATA, TOYOTA_dEALER_GLANCE_SERVICE_CAPACITY } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';

@Component({
  selector: 'app-generic-profile-table',
  templateUrl: './generic-profile-table.component.html',
  styleUrls: ['./generic-profile-table.component.scss']
})
export class GenericProfileTableComponent implements OnInit {

  @Input() genericProfileTableData;
  @Input() serviceCapacity;
  @Input() isTcuv: boolean = false;
  @Input() deptTdaag: boolean = false;
  @Input() serviceCap: boolean = false;
  @Input() tabIndex: number = 0;
  public indexTcuvTable: number = 0;

  public headerTitle;
  public year = '2024';

  constructor() { }
  public dataSource: any;
  ngOnInit(): void {
    if (this.genericProfileTableData && this.genericProfileTableData.tableData && this.genericProfileTableData.tableData.length > 0) {
      this.dataSource = this.genericProfileTableData?.tableData[0];
    }
    if (this.serviceCapacity && this.serviceCapacity.tableData) {
      this.dataSource = this.serviceCapacity?.tableData;
    }
    if (this.isTcuv) {
      this.headerTitle = 'PROFILE';
      this.indexTcuvTable = 1;
    } else {
      this.headerTitle = 'DEALER PROFILE'
    }
  }

}
