<div class="calendar-wrap">
  <input
    tabindex="1"
    class="date-picker"
    [class.rdr]="rdr"
    [value]="dateValue"
    [disabled]="disableInput"
    [readOnly]="readOnly"
    (dateInput)="handleInput($event)"
    (dateChange)="handleInputChange($event)"
    [min]="minDate"
    [max]="maxDate"
    [matDatepickerFilter]="datePickerFilter"
    [matDatepicker]="picker"
    placeholder="Choose a date"
    (mousedown)="openCalendar()"
  />
  <mat-datepicker-toggle
    class="date-picker-icon"
    [class.rdr-icon]="rdr"
    [class.disabled]="disableDatePicker"
    matSuffix
    [disableRipple]="true"
    [for]="picker"
  >
    <em
      class="tmi tmi-date"
      matDatepickerToggleIcon
      disableRipple
      tabindex="1"
    ></em>
  </mat-datepicker-toggle>
  <mat-datepicker [panelClass]="lexusClass" [disabled]="disableDatePicker" #picker></mat-datepicker>
</div>
