import { Pipe, PipeTransform } from '@angular/core';

import { assetUrl } from '../../../single-spa/asset-url';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'assetUrl'
})

export class AssetUrlPipe implements PipeTransform {

  constructor() {}

  public transform(value: string, folder?: string): any {
  // custom logic may be required to determine the environment based on application setup
  return assetUrl(value, folder, environment.spaHost);
  }

 }
 
