export function getObjFromFilteredArray(selectedFilter: any) {
  const obj = Object.fromEntries(selectedFilter.map(item => [item.key, item.value]));
  return obj;
}
export function getMonthAndYear(date) {
  let dateobj = new Date(date);
  const searchMonth = (dateobj.getMonth() + 1).toString();
  const searchYear = dateobj.getFullYear().toString();
  return { searchMonth, searchYear }
}
export function formattedResponseForCompanySelect(data) {
  const formatedData = data.map(val => {
    return {
      id: val.COMPANY_ID,
      name: val.COMPANY_NAME
    }
  })
  return formatedData;
}
export function formattedResponseForHierarchySelect(data) {
  const formatedData = data.map(val => {
    return {
      id: val.LEVEL_ID,
      name: val.LEVEL_NAME,
      COMPANY_ID: val.COMPANY_ID
    }
  })
  return formatedData;
}
export function getMonthNameFromNumber(month){
  const d = new Date();
  d.setMonth(month-1);
  const monthName = d.toLocaleString("default", {month: "long"});
  return monthName;
}
