import { Injectable } from '@angular/core';
import { Observable, Subscription, catchError, BehaviorSubject } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class CustomReportsService {
  private readonly showPdfExcelIcons = new BehaviorSubject<any>(false);

  constructor(public toast:ToastService,) { }

  public handleError() {
    return function (obsv: Observable<any>) {
      return obsv.pipe(
        catchError((err) => {
          console.log("error occured", err);
          this.toast.openSnackBarFail(err);
          this.spinnerService.hideSpinner();
          return err;
        })
      );
    };
  }

  public getPdfExcelIcons(): Observable<any> {
    return this.showPdfExcelIcons.asObservable();
  }

  public setPdfExcelIcons(flag:boolean): void {
    this.showPdfExcelIcons.next(flag);
  }
}