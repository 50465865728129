import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { FilterDateRangeComponent } from './filter-date-range.component';
import { DatePickerModule } from '../date-picker/date-picker.module';

@NgModule({
  declarations: [FilterDateRangeComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    DatePickerModule,
    MatIconModule
  ],
  exports: [FilterDateRangeComponent]
})
export class FilterDateRangeModule { }
