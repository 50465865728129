import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getMonthAndYear, getMonthNameFromNumber, getObjFromFilteredArray } from '../../utils/common-utils';
import { LexusPOSnapShotReport } from '../reportConstants';
import { cloneDeep } from 'lodash';
import * as _ from 'lodash';
import { DATA_FORMAT_FLAGS, reportUrlType } from 'src/app/constants/constants';
import { Router } from '@angular/router';


@Component({
  selector: 'app-lexus-po-snapshot',
  templateUrl: './lexus-po-snapshot.component.html',
  styleUrls: ['./lexus-po-snapshot.component.scss']
})
export class LexusPoSnapshotComponent implements OnInit {
  public lexusPOSnapShotjsonObj;
  public currentTabIndex = 0;
  private previousFilteredValue;
  public selectedFilter = [];
  public tabList: string[] = [];
  public obj = Object.values;
  public subscription: Subscription = new Subscription();
  public filtersSubscription: Subscription = new Subscription();
  public isIncentiveAnnouncementInputTable = false;
  public sources: Observable<any>[] = [];
  public incentiveAnnouncementInputArray = [];
  public selectedTabIndex = [0];
  public previousFilter;
  isFilterSame: boolean;
  monthName: string;
  public reportId: number;
  constructor(
    private readonly customReportsApiService: CustomReportsApiService,
    private readonly spinnerService: SpinnerService,
    private readonly filterService: CustomReportsFiltersService,
    private readonly customReportsService: CustomReportsService,
    public toast: ToastService,
    public route: Router,
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    this.tabList = LexusPOSnapShotReport.lexusPOSnapShotTabNames
    this.getLexusPOSnapShotReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
  }


  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    this.sources = [];
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1 && this.currentTabIndex !== 4 && this.currentTabIndex !== 5) {
      this.selectedTabIndex.push(tabIndex);
      this.filterService.setSelectedValues(this.selectedFilter);
    } else {
      this.spinnerService.hideSpinner();
    }
    this.enableStaticform();
  }

  public subscribeReportData() {
    this.filtersSubscription = this.filterService.getSelectedValues()?.pipe().subscribe({
      next: (filteredValues) => {
        if (filteredValues.length > 0) {
          this.spinnerService.displaySpinner();
          this.selectedFilter = filteredValues;
          this.isFilterSame = _.isEqual(this.selectedFilter, this.previousFilter);
          if (this.isFilterSame === false) {
            this.selectedTabIndex = [];
            this.selectedTabIndex.push(this.currentTabIndex);
          }
          if (this.currentTabIndex !== 4 && this.currentTabIndex !== 5) {
            // reset the object whenever user selects different filter
            if (this.lexusPOSnapShotjsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
              this.getLexusPOSnapShotReport(); //initialise to empty object
            }
            this.mapResponse();
          } else {
            this.spinnerService.hideSpinner();
          }
        } else {
          this.spinnerService.hideSpinner();
        }

      }, error: (error) => {
        console.error('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    });
  }

  public async mapResponse() {
    //when we have datasource and navigating back to same tab without change in filter do not make api call
    if (this.lexusPOSnapShotjsonObj?.tabList[this.currentTabIndex]?.datasource?.length === 0) {
      let currentTableResponse = [];
      await this.getTabDataSource(this.currentTabIndex);
      this.subscription = forkJoin(this.sources).subscribe({

        next: (resp) => {
          this.customReportsService.setPdfExcelIcons(true);
          if (this.currentTabIndex === 0) {
            let ytdSalesDataSource;
            let MtdSalesDataSource;
            if (resp[0]?.status === 200 && resp[0]?.body)
              ytdSalesDataSource = [{
                "datasourceName": "ytdSalesDataSource",
                "tableData": resp[0]?.body[0]?.reportData,
                "tableHeader": LexusPOSnapShotReport.YTDHeader,
                "tableSubHeader": LexusPOSnapShotReport.YTDSubHeader,
                "columnHeaderName": this.obj(resp[0]?.body[0]?.reportHeaderData[0]),
                "isRegionalTable": true,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns": 'all'

              }]

            if (resp[1]?.status === 200 && resp[1]?.body)
              MtdSalesDataSource = [{
                "datasourceName": "MtdSalesDataSource",
                "tableData": resp[1]?.body[0]?.reportData,
                "tableHeader": LexusPOSnapShotReport.MTDHeader,
                "tableSubHeader": LexusPOSnapShotReport.MTDSubHeader,
                "columnHeaderName": this.obj(resp[1]?.body[0]?.reportHeaderData[0]),
                "isRegionalTable": true,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns" : 'all'
              }]
            if (ytdSalesDataSource) {
              currentTableResponse.push(ytdSalesDataSource);
            }
            if (MtdSalesDataSource) {
              currentTableResponse.push(MtdSalesDataSource)
            }
          } else if (this.currentTabIndex === 1) {
            let ytd_top_ten_dealers;
            let AreaSalesPercMTDSalesDataSource;
            let AreaSalesPercYTDSalesDataSource

            if (resp[0]?.status === 200 && resp[0]?.body)
              ytd_top_ten_dealers = [{
                "tableData": resp[0]?.body,
                "tableHeader": LexusPOSnapShotReport.YTDTop10SalesHeader,
                "tableSubHeader": LexusPOSnapShotReport.YTDTop10SalesSubHeader,
                "datasourceName": "ytd_top_ten_dealers",
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns" : ['C_YTD', 'T_YTD']
              }]

            if (resp[2]?.status === 200 && resp[2]?.body)
              AreaSalesPercYTDSalesDataSource = [{
                "tableData": resp[2]?.body,
                "isRegionalTable": true,
                "datasourceName": "AreaSalesPercYTDSalesDataSource",
                "tableHeader": LexusPOSnapShotReport.LexusPOAreaSalesPercMTDSalesDataHeader,
                "removeLineBreak": true,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns" : ['NVS']
              }]

            if (resp[1]?.status === 200 && resp[1]?.body)
              AreaSalesPercMTDSalesDataSource = [{
                "tableData": resp[1]?.body,
                "isRegionalTable": true,
                "removeLineBreak": true,
                "datasourceName": "AreaSalesPercMTDSalesDataSource",
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns" : ['NVS']
              }]


            if (ytd_top_ten_dealers) {
              currentTableResponse.push(ytd_top_ten_dealers)
            }
            if (AreaSalesPercYTDSalesDataSource) {
              currentTableResponse.push(AreaSalesPercYTDSalesDataSource)
            }
            if (AreaSalesPercMTDSalesDataSource) {
              currentTableResponse.push(AreaSalesPercMTDSalesDataSource)
            }

          } else if (this.currentTabIndex === 2) {
            let mtd_best_record;
            let market_share;
            let lexus_po_grounded_vs_purchased;
            const MTDHeader = `${this.monthName} MTD BEST-EVER RECORDS`;
            let MTDBestRecHeader = { MTDBestRecHeader: [{ [MTDHeader]: '5' }] };

            if (resp[0]?.status === 200 && resp[0]?.body)
              mtd_best_record = [{
                "datasourceName": "mtd_best_record",
                "tableData": resp[0]?.body,
                "tableHeader": MTDBestRecHeader.MTDBestRecHeader,
                "tableSubHeader": LexusPOSnapShotReport.MTDBestRecSubHeader,
                "isRegionalTable": true,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns" : ['C_TOTAL_SALES','T_TOTAL_SALES']
              }]
            if (resp[1]?.status === 200 && resp[1]?.body)
              market_share = [{
                "datasourceName": "market_share",
                "tableData": resp[1]?.body,
                "tableHeader": LexusPOSnapShotReport.MarketShareHeader,
                "isRegionalTable": true,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.PercentWithZeroDecimal,
                "dateFormattingColumns" : 'all'
              }]
            if (resp[2]?.status === 200 && resp[2]?.body)
              lexus_po_grounded_vs_purchased = [{
                "datasourceName": "lexus_po_grounded_vs_purchased",
                "tableData": resp[2]?.body,
                "tableHeader": LexusPOSnapShotReport.lexusPOGroundedvsPurchasedHeader,
                "isRegionalTable": true,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns" : 'all'
              }]
            if (mtd_best_record) {
              currentTableResponse.push(mtd_best_record)
            }
            if (market_share) {
              currentTableResponse.push(market_share)
            }
            if (lexus_po_grounded_vs_purchased) {
              currentTableResponse.push(lexus_po_grounded_vs_purchased)
            }
          } else if (this.currentTabIndex === 3) {
            let competative_sales_analysis;
            if (resp[0]?.body)
              competative_sales_analysis = [{
                "tableData": resp[0]?.body,
                "tableHeader": LexusPOSnapShotReport.competativeSalesAnalysisHeader,
                "tableSubHeader": LexusPOSnapShotReport.competativeSalesAnalysisSubHeader,
                "defaultUnitFlag": DATA_FORMAT_FLAGS.UnitsWithZeroDecimal,
                "dateFormattingColumns" : 'all'
              }]
            if (competative_sales_analysis) {
              currentTableResponse.push(competative_sales_analysis)
            }
          }
          else if (this.currentTabIndex === 5) {
            this.isIncentiveAnnouncementInputTable = true;
          }
          else {

          }

          if (currentTableResponse.length !== 0) {
            this.lexusPOSnapShotjsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
          }
          this.previousFilter = cloneDeep(this.selectedFilter);
          //assign the current filter value
          this.spinnerService.hideSpinner();
        }, error: (err) => {
          console.error('Error handler: mapping response', err);
          this.spinnerService.hideSpinner();
        }
      })
    } else {
      this.spinnerService.hideSpinner();


    }
  }

  public async getTabDataSource(tabIndex: number) {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const dateObj = getMonthAndYear(obj['monthYear']);
    this.monthName = getMonthNameFromNumber(dateObj.searchMonth);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getLexusPOSnapshotExecutiveSummaryReportYTDSalesData(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      this.customReportsApiService.getLexusPOSnapshotExecutiveSummaryReportMTDSalesData(requestObj).pipe(this?.customReportsService.handleError()?.bind(this))
      ]
    } else if (tabIndex === 1) {
      this.sources = [this.customReportsApiService.getResultsForTopTenMTDSalesDealerNationally(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      this.customReportsApiService.getLexusPOAreaSalesPercMTDSalesData(requestObj).pipe(this.customReportsService?.handleError()?.bind(this)),
      this.customReportsApiService.getLexusPOAreaSalesPercYTDSalesData(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))

      ]
    } else if (tabIndex === 2) {
      this.sources = [this.customReportsApiService.getResultsForMTDBestRecords(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      this.customReportsApiService.getResultsForMarketShareRecords(requestObj).pipe(this.customReportsService?.handleError()?.bind(this)),
      this.customReportsApiService.getResultsForLexusPOGroundedVSPurchased(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
      ]
    } else if (tabIndex === 3) {
      this.sources = [this.customReportsApiService.getResultsForCompetativeSalesAnalysisData(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
      ]
    } else {
      this.spinnerService.hideSpinner();
    }
    return this.sources
  }



  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription?.unsubscribe();
    this.filtersSubscription?.unsubscribe();
    this.customReportsService.setPdfExcelIcons(false);

  }

  public enableStaticform() {
    this.incentiveAnnouncementInputArray = [];
    if (this.currentTabIndex === 4) {
      let incentiveObj = { title: LexusPOSnapShotReport.IncentivesHeader };
      let announcemetObj = { title: LexusPOSnapShotReport.AnnouncementsHeader };
      this.isIncentiveAnnouncementInputTable = true;

      this.incentiveAnnouncementInputArray.push(incentiveObj, announcemetObj)

    } else {
      this.isIncentiveAnnouncementInputTable = false;
    }
  }

  public getLexusPOSnapShotReport() {
    this.lexusPOSnapShotjsonObj = {
      tabList: [
        {
          tabName: this.tabList[0],
          filterCondition: 'LA - A - 04636 - AN TOYOTA CERRITOS',
          multiDataSources: true,
          datasource: []
        },
        {
          tabName: this.tabList[1],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[2],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[3],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[5],
          filterCondition: '',
          datasource: []
        }
      ]
    }
  }
}
