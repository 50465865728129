
<table [id]="'pdfTable-' + tabIndex" #genericTable aria-describedby="generic input table">
    <tr>
      <th class='dxfw-custom-report-table-header' colspan="2">{{tableObj?.title}}</th>
    </tr>
    <tr>
      <td>Western</td>
      <td><input/></td>
    </tr>
    <tr>
        <td>Central</td>
        <td><input/></td>
      </tr>
      <tr>
        <td>Eastern</td>
        <td><input/></td>
      </tr>
      <tr>
        <td>Southern</td>
        <td><input/></td>
      </tr>
      <tr>
        <td>Nat'l</td>
        <td><input/></td>
      </tr>
  </table>
  <br/>