<mat-tab-group (focusChange)="tabChange($event.index)">
  <div *ngIf="lexusDealerAtGlancejsonObj?.tabList">
    <mat-tab *ngFor="let tab of lexusDealerAtGlancejsonObj?.tabList">
      <ng-template mat-tab-label>{{ tab.tabName }}</ng-template>
      <div
        [ngClass]="lexusDealerAtGlancejsonObj?.tabList[currentTabIndex]?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
        <div
          *ngFor="let datasource of lexusDealerAtGlancejsonObj?.tabList[currentTabIndex]?.datasource ; let  i = index "
          class="dfxw-lexus-table">
          <app-multi-header-table *ngIf="!isProfileTable" [multiHeaderTableData]='datasource' [reportId]="reportId">
          </app-multi-header-table>
          <div #pdfElement *ngIf="isProfileTable" class='dxfw-content-table-container'>
            <app-generic-profile-table [genericProfileTableData]='datasource'></app-generic-profile-table>
          </div>
        </div>
      </div>
      <div class="dxfw-customreport-no-data-label"
        *ngIf="lexusDealerAtGlancejsonObj?.tabList[currentTabIndex]?.datasource?.length === 0">Sorry, there are no
        results that match your search criteria.</div>
      <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div>

    </mat-tab>
  </div>
</mat-tab-group>