<section>
  <h3 mat-dialog class='dialogHeader'>WORKOUT FINANCIALS DISCLAIMER</h3>
  <div class='mat-typography dialogBody'>
    The financial data you are about to access is confidential and may not be shared outside of TMS in any manner that
    may disclose information about individual dealers or small groups of dealers.
    <br/>
    <br/>
    Any questions about whether it is permissible to share this information should be directed to the TMS Business
    Management or TMS Legal Department
    <br/>
 </div>

  <div class='dialogFooter'>
    <button class='action-button primary-button' mat-button cdkFocusInitial
    (click)="acceptModal()">ACCEPT</button>
    <button class='static-button secondary-button' mat-button mat-dialog-close
     (click)="declineModal()">DECLINE</button>
  </div>
</section>