import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { DropDownListComponent } from './drop-down-list.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DropDownListComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    
  ],
  exports: [DropDownListComponent]
})
export class DropDownListModule { }
