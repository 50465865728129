<mat-tab-group (focusChange)="tabChange($event.index)">
  <div *ngIf="lexusPOSnapShotjsonObj?.tabList">
    <mat-tab #tabContent *ngFor="let tab of lexusPOSnapShotjsonObj?.tabList">
      <ng-template mat-tab-label>{{ tab.tabName }}</ng-template>
      <div *ngIf="!isIncentiveAnnouncementInputTable"
        [ngClass]="lexusPOSnapShotjsonObj?.tabList[currentTabIndex]?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
        <div *ngFor="let currdataSource of lexusPOSnapShotjsonObj?.tabList[currentTabIndex]?.datasource; let i = index">
          <div *ngFor="let datasource of currdataSource">
            <app-multi-header-table [tableIndex]="i" [multiHeaderTableData]='datasource' [reportId]="reportId">
            </app-multi-header-table>
          </div>
        </div>
      </div>
      <div *ngIf="isIncentiveAnnouncementInputTable" class='dxfw-content-table-container'>
        <div *ngFor="let obj of incentiveAnnouncementInputArray;let j=index">
          <app-generic-input-table [tabIndex]="j" [tableObj]="obj"></app-generic-input-table>
        </div>
      </div>
      <div class="dxfw-customreport-no-data-label"
        *ngIf="lexusPOSnapShotjsonObj?.tabList[currentTabIndex]?.datasource?.length === 0 && selectedFilter?.length !== 0">
        Sorry, there are no results that match your search criteria.</div>
      <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div>

    </mat-tab>
  </div>
</mat-tab-group>