import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from '../../app-routing.module';
import { QuicksightReportsComponent } from '../../component/quicksight-reports/quicksight-reports.component';
import { MaterialModule } from './../../material.module';
import { HttpInterceptorService } from '../../services/http-interceptor.service';
import { ReportGridComponent } from './components/report-grid/report-grid.component';
import { ReportSideFilterComponent } from './components/report-side-filter/report-side-filter.component';
import { ReportContentComponent } from './components/report-content/report-content.component';
import { PipeModule } from "../pipes/pipe.module";
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        QuicksightReportsComponent,
        ReportGridComponent,
        ReportSideFilterComponent,
        ReportContentComponent
    ],
    imports: [
      CommonModule,
      AppRoutingModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      MaterialModule,
      DragDropModule,
      PipeModule
  ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        }
    ],
    exports: []
    
})
export class WorkoutSalesModule {}