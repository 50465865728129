<div class="dxfw-grid-title">
  <div class="grid-header">
    <span *ngIf="!isReportType()" class="title pr-3">{{reportTitle}} </span>
    <span *ngIf="isReportType()" class="title pr-3">{{selectedHierarchyName}} {{reportTitle}} </span>
    <div class="right-btn">
      <div class="dxfw-workout-header">
        <button mat-button class="secondary-button back-btn" (click)="goBack()">{{backBtnTitle}}</button>
      </div>
    </div>
  </div>

  <div>
    <mat-sidenav-container>
      <mat-sidenav #sidenav [opened]="true" [mode]="'side'" position="start">
        <side-nav [reportId]="reportId" [sideNav]="sidenav" [requiredFilters]="requiredFilters"
          [menuTemplate]="menuTemplate" [title]="'Filter'" (searchChips)="addFilterChips($event)"
          (clearFilter)="clearFilterChip($event)" [searchFilterObject]="searchObject"
          (searchFilter)="onSearchButtonClick()" (selectedDealers)="addSelectedDealers($event)" [removedFilterKey]="removedKey"></side-nav>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class='chips-container'>
          <ul class="search-chip">
            <ng-container *ngFor="let search of currentSearchFilter;let i=index;">
              <li>
                {{ search.key !== 'dealer' ? (search?.value.name ? search?.value?.name : search.value) : search.value.MEMBER_NAME }} <em class="tmi tmi-close" (click)="removeFilter(i, search)"></em>
              </li>
            </ng-container>
          </ul>

          <div *ngIf="showButtons" class="right-btn">
            <mat-icon class="custom-icons" (click)="customReportExportPdfService.exportPdf()">picture_as_pdf</mat-icon>
            <svg (click)="exportExcelJS()" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><path fill="currentColor" d="M14 2H6a2 2 0 0 0-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8zm4 18H6V4h7v5h5zm-5.1-5.5l2.9 4.5H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 10H10l2 3.4l2-3.4h1.8z"/></svg>
          </div>

          <div class="right-btn" *ngIf="multiSelectFilter && multiSelectFilter.length > 1">
            <ng-container>
              <button [disabled]="selectedIndex === 0" class="multiselect-arrowbtn">
                <mat-icon class='mat-icon multiselect-arrowicon"' (click)="arrowClick(false)">arrow_back_ios
                </mat-icon>
              </button>

              <button [disabled]="selectedIndex >= multiSelectFilter?.length - 1" class="multiselect-arrowbtn">
                <mat-icon class='mat-icon' (click)="arrowClick(true)">arrow_forward_ios</mat-icon>
              </button>
            </ng-container>
            <!-- <button class="primary-button small-button" (click)="removeAllFilters()">Clear All</button> -->
          </div>
        </div>

        <mat-sidenav-container>
          <mat-sidenav-content>
            <router-outlet></router-outlet>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>


<ng-template #menuTemplate></ng-template>