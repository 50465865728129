
<div class="bulk-upload-container">
    <label class="file-upload-header">Workout User Registration</label>
    <div class="file-upload-section">
        <div class="file-select">
            <label for="file-upload">Select a file:</label>
            <input type="file" required #fileInput (change)="fileChange($event)" accept=".csv" />
        </div>
        <div class="file-input">
            <input type="text" [value]="selectedFileName" readonly />
            <button (click)="fileInput.click()">Browse</button>
        </div>
    </div>
    <button (click)="bulkRegistration()" [disabled]="hideButton || isSuccess">Bulk Registration</button>
    <!-- {{message}} -->
    <div *ngIf="message" [ngClass]="{'success-message': isSuccess, 'error-message': !isSuccess}">{{ message }}
    </div>
    <div *ngFor="let item of summary">{{ item.message }}</div>
</div>