import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, EventEmitter, Output, ViewChild, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-part-department-model',
  templateUrl: './part-department-model.component.html',
  styleUrls: ['./part-department-model.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartDepartmentModelComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<PartDepartmentModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data,

  ) { }

  public ngOnInit() { }

  closeModal(): void {
    this.dialogRef.close();
  }
}
