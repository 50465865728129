import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-side-filter',
  templateUrl: './report-side-filter.component.html',
  styleUrls: ['./report-side-filter.component.scss']
})
export class ReportSideFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
