import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IGridSearch } from '../interfaces/workout-reports.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  private readonly clearFilters = new BehaviorSubject<IGridSearch>({});
  private readonly currentFilters = new BehaviorSubject<IGridSearch[]>([]);
  private readonly currentGridFilters = new BehaviorSubject<IGridSearch[]>([]);
  private readonly currentDelartSelectedArrowFilters = new BehaviorSubject<IGridSearch[]>([]);

  constructor() { }

  public setRemovedValues(removedCol: IGridSearch): void {
    this.clearFilters.next(removedCol);
  }

  public getRemovedVal(): Observable<IGridSearch> {
    return this.clearFilters.asObservable();
  }

  public getSelectedValues(): Observable<IGridSearch[]> {
    return this.currentFilters.asObservable();
  }

  public setSelectedValues(cols: IGridSearch[]): void {
    this.currentFilters.next(cols);
  }
  public getArrowSelectedValues(): Observable<IGridSearch[]> {
    return this.currentDelartSelectedArrowFilters.asObservable();
  }

  public serArrowSelectedValues(cols: IGridSearch[]): void {
    this.currentDelartSelectedArrowFilters.next(cols);
  }

  public getSelectedGridFilterValues(): Observable<IGridSearch[]> {
    return this.currentGridFilters.asObservable();
  }

  public setSelectedGridFilterValues(cols: IGridSearch[]): void {
    this.currentGridFilters.next(cols);
  }


}

