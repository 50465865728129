import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { COMPANY_TYPE, HIERARCHY_TYPE,reportUrlType } from 'src/app/constants/constants';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';
import { PsFinancialReport } from '../reportConstants';
 
 
@Component({
  selector: 'app-ps-financial',
  templateUrl: './ps-financial.component.html',
  styleUrls: ['./ps-financial.component.scss']
})
export class PandSfinancialComponent implements OnInit {
 
  public currentTabIndex: number = 0;
  public selectedFilter = [];
  public subscription: Subscription = new Subscription();
  public selectedTabIndex = [0];
  public PandSfinancialComponentjsonObj;
  public sources: Observable<any>[] = [];
  public obj = Object.values;
  public selectedYear;
  selectedDealerCode: string;
  public reportId: number;
  public formattedSelectedFilter;
  public reportTitleToDisplay: string;
  public monthYear:any = {};
   public noDataToDisplayMsg: string;
   selectedFilteredValues: any = [];
 
  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
 
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    this.getPandSfinancialReport();
  }
 
  ngOnInit(): void {
    this.subscribeReportData();
  }
 
 
  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }
 
 
  public getTabDataSource(selectedFilter: any) {
    const obj = getObjFromFilteredArray(selectedFilter);
    const dateObj = getMonthAndYear(obj['monthYear']);
    this.monthYear = ' - ' + dateObj.searchMonth + '/' + dateObj.searchYear;
    const hierarchyObj = obj['hierarchy'];
    const viewId = this.getViewId(obj['company'],hierarchyObj.name);
    let requestObj;
    const filterObj = obj['dealer'];
    if (obj['hierarchy']?.id === HIERARCHY_TYPE.DEALER) {   // 10 - Dealer                                
      requestObj = this.createPayload(dateObj.searchMonth, filterObj.REGION_CODE,  filterObj.DISTRICT_CODE, dateObj.searchYear, filterObj.MEMBER_CODE, viewId);
      this.reportTitleToDisplay = filterObj.DISTRICT_CODE + ' - ' + filterObj.MEMBER_CODE + ' - ' + filterObj.MEMBER_NAME;
    } else if (obj['hierarchy']?.id ===  HIERARCHY_TYPE.DISTRICT) {  // 11 - District
      this.reportTitleToDisplay = filterObj.DISTRICT_CODE + ' - ' + filterObj.MEMBER_NAME.toUpperCase();
      requestObj = this.createPayload(dateObj.searchMonth, filterObj.REGION_CODE, filterObj.DISTRICT_CODE, dateObj.searchYear, null, viewId);
    } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.REGION) {  // 12 - Region
      this.reportTitleToDisplay = filterObj.REGION_CODE + ' ' + ' - ' + ' '+  filterObj.MEMBER_NAME.toUpperCase();
      requestObj = this.createPayload(dateObj.searchMonth, filterObj.REGION_CODE, null, dateObj.searchYear, null, viewId);
    } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.NATIONAL) {   // 13 - National
      requestObj = this.createPayload(dateObj.searchMonth, null, null, dateObj.searchYear, filterObj.MEMBER_CODE, viewId);
    }
    this.sources = [this.customReportsApiService.getPsFinancialReportData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]
    return this.sources;
  }
 
  public createPayload(searchMonth, region, district, searchYear, dealer, viewId) {
    const requestObj = {
    view_id: viewId,
    month: searchMonth,
    year: searchYear,
    levofdetail: dealer ? 'dealer' : district ? 'district': 'region',
    region_no : region,
    district_cd : dealer ? district : district ? district: "0",
    dealerno:dealer
    }
    return requestObj;
  }
 
 
  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (selectedFilter) => {
          if (selectedFilter?.length > 0) {
            this.selectedFilteredValues = selectedFilter;
            this.spinnerService.displaySpinner();
            this.mapResponse(selectedFilter);
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }
 
  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
    this.customerReportService.setPdfExcelIcons(false);
  }
 
  public getPandSfinancialReport() {
    this.PandSfinancialComponentjsonObj = {
      multiDataSources: true,
      datasource: []
 
    }
  }
 
  public async mapResponse(selectedFilter: any) {
    let currentTableResponse = [];
    await this.getTabDataSource(selectedFilter);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        this.customerReportService.setPdfExcelIcons(true);
        let columnHeaders: any = [];
        const obj = getObjFromFilteredArray(selectedFilter);
        const companyCode = obj['company']?.id;
        const dateObj = getMonthAndYear(obj['monthYear']);
        const currentYear = Number(dateObj.searchYear);
        const previousYear = currentYear - 1;
        if (resp[0]?.status === 200 && resp[0]?.body) {
          let reportData = resp[0]?.body[0]?.reportData;
          if (reportData?.length > 0) {
            reportData.forEach((ele, index) => {
              columnHeaders = ['', currentYear, previousYear, 'CHANGE', currentYear, previousYear, 'CHANGE'];
              if (ele.sectionData?.length > 0) {
                const obj = [{
                  "datasourceName": `PandSFinacial ${index}`,
                  "tableData": ele.sectionData,
                  "sectionTitle": ele.SECTIONTITLE,
                  "sectionId": ele.SECTIONID,
                  "tableHeader": PsFinancialReport.psFinanceHeader,
                  "columnHeaderName": companyCode === COMPANY_TYPE.LEXUS ? this.getColumnHeaders(columnHeaders,ele) : columnHeaders,
                  "dateFormattingColumns" : 'all',
                  "removeLineBreak" : true,
                  "showRowBorder": true
                }];
                currentTableResponse.push(obj);
              }
            });
          }
        }
        this.PandSfinancialComponentjsonObj.datasource = currentTableResponse;
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }
  
  public getColumnHeaders(columnHeaders, ele) {
    columnHeaders[0] = ele.SECTIONTITLE;
    return columnHeaders;
  }

  public getViewId(company,levelName) {
    const companyName = company?.name.toLowerCase();
    return `pAndSFinancialService_${companyName}_${levelName?.toLowerCase()}`;
  }
 
}
 
 
 
 