export class Constants {

  public static readonly forgeRockSessionTime = 45 * 60 * 1000;
  public static readonly FinanceReports = "Workout Financials";

}

export const appConfig = {
  dateFormat: 'MM/DD/YYYY',
  defaultFilterRange: 30,
  dealerId: '24022',
  testMode: false
}

export const filterValues = {
  monthYear: 'monthYear'
}

export const FIELDS_FOR_BulkUserRegistration = {
  userId: 'User Id',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  accessGroupName: 'Access Group Name',
  roleName: 'Role Names'
};

export const reportId = {
  LEXUS_PO_SNAPSHOT: 72,
  TCUV_DEALER_GLANCE: 74,
  LEXUS_DEALER_GLANCE: 73,
  LEXUS_SALES_GLANCE: 71,
  TOYOTA_DEALER_GLANCE: 83,
  PARTS_DEPARTMENT: 68,
  SERVICE_DEPARTMENT: 69,
  SALES_ANALYSIS_NEW_VEHICLE: 77,
  SALES_ANALYSIS_USED_VEHICLE: 78,
  SERVICE_ANALYSIS: 75,
  PARTS_ANALYSIS: 76,
  PS_FINANCIAL: 93,
  NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT: 91,
}
export const toyotaReports = [
  reportId.TCUV_DEALER_GLANCE, reportId.TOYOTA_DEALER_GLANCE
]
export const lexusReports = [
  reportId.LEXUS_DEALER_GLANCE, reportId.LEXUS_SALES_GLANCE
]



export const reportUrlType = {
  'lexus-po-snapshot': reportId.LEXUS_PO_SNAPSHOT,
  'tcuv-dealer-at-glance': reportId.TCUV_DEALER_GLANCE,
  'lexus-dealer-at-glance': reportId.LEXUS_DEALER_GLANCE,
  'lexus-sales-at-glance': reportId.LEXUS_SALES_GLANCE,
  'toyota-dealer-at-glance': reportId.TOYOTA_DEALER_GLANCE,
  'part-department-analysis': reportId.PARTS_DEPARTMENT,
  'service-dept-analysis': reportId.SERVICE_DEPARTMENT,
  'sales-analysis-used-vehicle': reportId.SALES_ANALYSIS_USED_VEHICLE,
  'sales-analysis-new-vehicle': reportId.SALES_ANALYSIS_NEW_VEHICLE,
  'service-analysis': reportId.SERVICE_ANALYSIS,
  'parts-analysis': reportId.PARTS_ANALYSIS,
  'ps-financial':reportId.PS_FINANCIAL,
  'national-dpr':reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT
}
export enum HIERARCHY_TYPE {
  DEALER = 10,
  DISTRICT = 11,
  REGION = 12,
  NATIONAL = 13
}

export enum COMPANY_TYPE {
  TOYOTA = 14,
  LEXUS = 15
}

export const REPORT_MSGS = {
  NO_RECORDS_FOUND: 'No data available for the selected criteria'
}
export const DATA_VALIDATION_MEGS = {
  NO_DATA_FOUND: 'No data found',
  REQUIRED_FIELD_MSGS: 'Please select the required field.'
}

export enum DATA_FORMAT_FLAGS {
  DollarWithZeroDecimal = '81',
  PercentWithOneDecimal = '82',
  UnitsWithOneDecimal = '83',
  UnitsWithZeroDecimal = '84',
  RatioWithTwoDecimal = '85',
  UnitsWithTwoDecimal = '86',
  PercentWithZeroDecimal = '87',
  DollarWithTwoDecimal = '88',
  PercentWithTwoDecimal = '810',
  ValueWithYesORNo = '811',
  RatioWithOneDecimal = '813'
}