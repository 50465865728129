<table [id]="'pdfTable-' + tableIndex"  #pdfTable mat-table [dataSource]="multiHeaderTabledataSource" aria-describedby="multiheader table" *ngIf = "multiHeaderTableData.datasourceName !== 'tcuvfinance' && multiHeaderTableData.datasourceName !== 'parts_data' && multiHeaderTableData.datasourceName !== 'parts_access_data'"
  class="mat-elevation-z8 " [ngClass]="isRegionalTable ? 'dxfw-ytd-mtd-cell-class' : 'dxfw-alt-wht-gry-class'">
 
  <!-- to display columns if we get it from BE else We have defined columns -->
  <ng-container [matColumnDef]="col" *ngFor="let col of multiHeaderdisplayedColumns; let i=index">
    <ng-container *ngIf="displayColumnName?.length > 0">
      <th mat-header-cell *matHeaderCellDef class="dxfw-custom-report-table-header" [ngClass]="showBorder(col, i)" [attr.colspan]="1">
      {{displayCustomizedHeaderName(displayColumnName[i]) }} </th>
    </ng-container>
    <ng-container *ngIf="displayColumnName?.length === 0 ">
      <th mat-header-cell *matHeaderCellDef class="dxfw-custom-report-table-header" [ngClass]="showBorder(col, i)" [attr.colspan]="1">
       {{displayCustomizedHeaderName(col)}}
      </th>
      <span></span>
    </ng-container>
 
    <td mat-cell *matCellDef="let element; let a= index;"  [ngClass]="[i === 0 ? tableColumnClass :'dxfw-custom-report-table-cell-center', i=== 1 && multiHeaderTableData?.showLineNumbers ? 'left-align-column' : '',
        checkNullValOfGPColumm(col,element) ?'dxfw-custom-report-table-cell-bgcolor':'',showBorder(col, i),
      ]">
        <span
        [ngClass]="{'dxfw-custom-report-table-cell-highlightcolor': checkDifferenceColValue(col, element)}" [style.color]="col === 'CMVALUE' ? element.FONTCOLOR : ''">{{element[col]
        === "0" || element[col] === "NULL" ? '' : (element[col] | dataFormattingPipe: col : defaultUnitFlag ? defaultUnitFlag :  element['UNITS_FLAG'] : dataFormattingColumns)}}</span>  

      <span *ngIf="multiHeaderTableData?.showLineNumbers && i== 0">{{a+1}}</span>
 
      <!-- <span *ngIf="isPartsDepartmentReport && i==1"> <a class="dxfw-custom-report-table-cell-highlightcolumn"
          (click)="openModel()">{{element[col] === "0" ? '' :
          element[col]}}</a></span> -->
 
      <span *ngIf="displayingInput(col, element)">
        <mat-form-field class="dxfw-part-detparment-report-input">
          <input matInput placeholder="" value="">
        </mat-form-field>
      </span>
 
    </td>
  </ng-container>
 
  <ng-container *ngIf="tableSubHeaderColumns?.length > 0 && subHeaderColumns?.length > 0">
      <ng-container [matColumnDef]="col"  *ngFor="let col of obj(subHeaderColumns[0])">
    <th mat-header-cell class="dxfw-custom-report-table-header" *matHeaderCellDef [attr.colspan]="subHeaderColumns[0][col]">{{col}}</th>
  </ng-container>
</ng-container>
<ng-container *ngIf="tableSubHeaderColumns2?.length > 0 && subHeaderColumns2?.length > 0">
  <ng-container [matColumnDef]="col"  *ngFor="let col of obj(subHeaderColumns2[0])">
    <th mat-header-cell class="dxfw-custom-report-table-header" *matHeaderCellDef [attr.colspan]="subHeaderColumns2[0][col]">{{col}}</th>
  </ng-container>
</ng-container>
 
  <!-- to display the header -->
  <ng-container *ngIf="tableFirstHeaderColumns?.length > 0">
    <ng-container [matColumnDef]="col" *ngFor="let col of tableFirstHeaderColumns">
      <ng-container *ngFor="let keyvaluepair of tableFirstHeader ">
        <th mat-header-cell [ngClass]="tableHeaderClass ? tableHeaderClass : 'dxfw-custom-report-table-header'"
          *matHeaderCellDef [attr.colspan]="keyvaluepair[col]">{{col}}</th>
      </ng-container>
    </ng-container>
  </ng-container>
 
  <ng-container *ngIf="tableFirstHeaderColumns?.length >0">
    <tr mat-header-row *matHeaderRowDef="tableFirstHeaderColumns"></tr>
  </ng-container>
 
  <ng-container *ngIf="tableSubHeaderColumns?.length >0">
  <tr mat-header-row *matHeaderRowDef="tableSubHeaderColumns"></tr>
</ng-container>
<ng-container *ngIf="tableSubHeaderColumns2?.length >0">
  <tr mat-header-row *matHeaderRowDef="tableSubHeaderColumns2"></tr>
</ng-container>
 
 
 
  <tr mat-header-row *matHeaderRowDef="multiHeaderdisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: multiHeaderdisplayedColumns;"></tr>
</table>
<div *ngIf="!multiHeaderTableData?.removeLineBreak">
  <br />
</div>
 
 
<div class="table-container" *ngIf = "multiHeaderTableData.datasourceName === 'tcuvfinance' || multiHeaderTableData.datasourceName === 'parts_access_data' || multiHeaderTableData.datasourceName === 'parts_data' ">
  <table aria-describedby="profile table">
    <thead>
      <tr *ngFor="let col of tableFirstHeaderColumns">
        <th class="dxfw-tcuv-header" id="mainheader" colspan="10">{{col}}</th>
      </tr>
      <tr>
      <ng-container *ngIf="subHeaderColumns?.length > 0">
        <ng-container *ngFor="let cols of obj(subHeaderColumns[0])">
        <th class="dxfw-custom-report-table-header" [attr.colspan]="subHeaderColumns[0][cols]">{{cols}}</th>
      </ng-container>
      </ng-container>
      </tr>
      <tr>
          <th class="dxfw-custom-report-table-header" id="header" *ngFor="let columnName of displayColumnName">{{ columnName }}</th>
      </tr>
  </thead>
  <tbody>
        <tr>
          <td class="mat-cell dxfw-custom-report-table-cell-left-align sectionttlClass" colspan="10">{{ sectionTitle}}</td>
        </tr>
        <ng-container *ngFor="let item of multiHeaderTabledataSource?.data">
        <tr>
          <td class="mat-cell " *ngFor="let val of multiHeaderdisplayedColumns; let i =index;" [ngClass]="i == 0? 'widthClass' : 'dxfw-custom-report-table-cell-center'">{{ item[val] }}</td>
      </tr>
      </ng-container>
    </tbody>
</table>
</div>
 