export const MockReportListResponseDEV = [
  {
    name: 'Daily AM Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/0714fd0e-638a-4aad-a249-e59383bea94e?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '0714fd0e-638a-4aad-a249-e59383bea94e'
  },
  {
    name: 'Dealer Sales Summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/ac97b5f2-2729-476f-a5a1-6e5f348688c3?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'ac97b5f2-2729-476f-a5a1-6e5f348688c3'
  },
  {
    name: 'MTD Dealer Sales Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/7a636691-6d4d-4649-929f-83376a8bbabd?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '7a636691-6d4d-4649-929f-83376a8bbabd'
  },
  {
    name: 'Sales Categories',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/67c27c4b-9ab8-4279-b551-2cf77ff2fe34?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '67c27c4b-9ab8-4279-b551-2cf77ff2fe34'
  },
  {
    name: 'Custom Sales Categories',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/d3d58acd-758d-4d22-b6e1-fba4d909ea7b?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'd3d58acd-758d-4d22-b6e1-fba4d909ea7b'
  },
  {
    name: 'Sales to Availability Review',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/55e9bbfa-0415-42be-a10c-b2405d0e136d?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '55e9bbfa-0415-42be-a10c-b2405d0e136d'
  },
  {
    name: 'Daily, MTD and YTD Sales & Objectives',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/130f0a03-dd47-4b0a-858f-b6f3b79032d3?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '130f0a03-dd47-4b0a-858f-b6f3b79032d3'
  },
  {
    name: 'Sales Summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/7e38bd88-90a0-4fba-a8fb-d954d950a40b?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '7e38bd88-90a0-4fba-a8fb-d954d950a40b'
  },
  {
    name: 'MTD Sales vs Stock % Region',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/8fb430b9-1938-4992-9d4c-40f787765d93?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '8fb430b9-1938-4992-9d4c-40f787765d93'
  },
  {
    name: 'Sales Market Share Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/8c637b3f-ebb4-4c3c-85cc-75befff7c3ba?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '8c637b3f-ebb4-4c3c-85cc-75befff7c3ba'
  },
  {
    name: 'CYPY Sales Analysis',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/e01bab91-e447-4615-8cf4-73d87383115f?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'e01bab91-e447-4615-8cf4-73d87383115f'
  },
  {
    name: 'Sales Categories Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/1dc5e7ad-e178-433a-8ab7-e1bf7f1732cc?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '1dc5e7ad-e178-433a-8ab7-e1bf7f1732cc'
  },
  {
    name: 'CYTD, PYTD Percent Change',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/578f6ea6-12a7-4072-996f-dd38d6684544?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '578f6ea6-12a7-4072-996f-dd38d6684544'
  },
  {
    name: 'MTD Dealer Truck Sales Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/9e2fcb04-940f-4d40-9fde-53a353f791c4?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '9e2fcb04-940f-4d40-9fde-53a353f791c4'
  },
  {
    name: 'Sales To Availability 4 Month Window',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/48b97d87-2426-4112-a2a5-08877da825c1?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '48b97d87-2426-4112-a2a5-08877da825c1'
  },
  {
    name: 'Yearly Tracking Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/cc8c5478-50db-4ca9-8e49-1ca67fd57f71?directory_alias=tmnaqsadmin',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'cc8c5478-50db-4ca9-8e49-1ca67fd57f71'
  },
  {
    name: 'Daily AM Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/08b946fe-22a0-4b2e-ba97-076b505fc304?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '08b946fe-22a0-4b2e-ba97-076b505fc304'
  },
  {
    name: 'National - Top 300 Ranking- Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/bc8ab78d-bdf6-41ea-b399-d07b03779758?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'bc8ab78d-bdf6-41ea-b399-d07b03779758'
  },
  {
    name: 'Entity Summary',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/43531295-4548-4070-84b9-984fb89dd398?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '43531295-4548-4070-84b9-984fb89dd398'
  },
  {
    name: 'Entity - Top 300  Ranking - Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/4577c312-062c-4d2c-a819-9cedae0515e7?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '4577c312-062c-4d2c-a819-9cedae0515e7'
  },
  {
    name: 'Public Entity CYPY Graph',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/2c925920-9ffc-4177-95dd-0635cfaca2c8?directory_alias=tmnaqsadmin',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '2c925920-9ffc-4177-95dd-0635cfaca2c8'
  },
  {
    name: 'Level 2 Entity summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/5aa040c4-09e4-48eb-9469-d0aab46ea7c6?directory_alias=tmnaqsadmin',
    department: 'Entity',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '5aa040c4-09e4-48eb-9469-d0aab46ea7c6'
  },
  {
    name: 'Sales To Availability Review Entity Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/0de2471e-4156-4dd1-a67f-28d45d6edeef?directory_alias=tmnaqsadmin',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '0de2471e-4156-4dd1-a67f-28d45d6edeef'
  },
  // {
  //   name: 'MTD Sales vs Stock % Region Entity Report',
  //   display: true,
  //   url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/97a5410c-244c-47c2-926e-72ea120bbeb5?directory_alias=tmnaqsadmin',
  //   department: 'Entity',
  //   frequency: 'Daily',
  //   dataAsOf: '--',
  //   dashboardId: '97a5410c-244c-47c2-926e-72ea120bbeb5'
  // },
  {
    name: 'Sales Market Share Entity Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/cde8f1ef-219f-48fe-a581-2791bf991fcc?directory_alias=tmnaqsadmin',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'cde8f1ef-219f-48fe-a581-2791bf991fcc'
  },
  {
    name: 'Daily MTD And YTD Sales And Objectives Entity Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/2fd7b5eb-78b5-4427-8eb8-9044d24a3fdc?directory_alias=tmnaqsadmin',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '2fd7b5eb-78b5-4427-8eb8-9044d24a3fdc'
  },
  {
    name: 'Sales Categories Entity Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/5a93d9df-368c-4431-9b67-cd7ca148f245?directory_alias=tmnaqsadmin',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: ''
  },
  {
    name: 'Custom Sales Categories Entity Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/948728f8-eef9-4167-87da-700e8882bc8d?directory_alias=tmnaqsadmin',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '948728f8-eef9-4167-87da-700e8882bc8d'
  },
  {
    name: 'TCUV Daily Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/acac8093-fb02-4883-88b2-9af1bb8b4821?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'acac8093-fb02-4883-88b2-9af1bb8b4821'
  },
  {
    name: 'TCUV Sales Ranking',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/a994f687-f470-46ad-983a-a346856cdbdd?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'a994f687-f470-46ad-983a-a346856cdbdd'
  },
  {
    name: 'TCUV Daily AM Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/3813fbe0-1974-4c66-a9a3-14742a08ac5b?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '3813fbe0-1974-4c66-a9a3-14742a08ac5b'
  },
  {
    name: 'National - Top Ranking Dealers',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/6b44d189-de77-4c3f-a644-1f0cf1e18f2a?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '6b44d189-de77-4c3f-a644-1f0cf1e18f2a'
  },
  {
    name: 'TCUV Source of Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/a73431c4-1345-4769-b8c4-06ae6957e593?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'a73431c4-1345-4769-b8c4-06ae6957e593'
  },
  {
    name: 'TCUV Data Dump Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/7280848e-ac83-4ec2-8668-a759f351f684?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '7280848e-ac83-4ec2-8668-a759f351f684'
  },
  {
    name: 'TCUV Sales by Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/1777a1e3-a5e7-4597-b96b-67598cd07528?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '1777a1e3-a5e7-4597-b96b-67598cd07528'
  },
  {
    name: 'TCUV Certifications By Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/f5400e76-2d20-452b-bfb9-797ad0cdffe2?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'f5400e76-2d20-452b-bfb9-797ad0cdffe2'
  },
  {
    name: 'Toyota Certified Sales And Stock Summary Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/bcca627b-6bee-4b55-b44c-6b06937d19f3?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'bcca627b-6bee-4b55-b44c-6b06937d19f3'
  },
  {
    name: 'Lexus CPO Sales Summary', //Phase-5
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/e3d559d6-3f0c-46c4-aeb6-c21457fa1031?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'e3d559d6-3f0c-46c4-aeb6-c21457fa1031'
  },
  {
    name: 'Lexus Pre-Owned Sales Ranking',
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/9b47ab2b-80fd-4713-9f1e-67ddb8cfc8c3?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '9b47ab2b-80fd-4713-9f1e-67ddb8cfc8c3'
  },
  {
    name: 'Lexus CPO Yearly Tracking',
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/b7fe17dd-0109-47d4-9bd0-17a077525fe1?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'b7fe17dd-0109-47d4-9bd0-17a077525fe1'
  },
  {
    name: 'Lexus CPO Rolling Year Trend',
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/2b7fd988-acbc-4a38-a94c-137259fba80c?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '2b7fd988-acbc-4a38-a94c-137259fba80c'
  },
  {
    name: 'Daily Sales-No Sales Report - Region',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/3c42a315-ed74-42cd-8185-ac92e87859e1?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '3c42a315-ed74-42cd-8185-ac92e87859e1'
  },
  {
    name: 'Lexus Daily Sales Summary',
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/86e0bf15-d1a8-4481-b9bd-f6facc90ef65?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '86e0bf15-d1a8-4481-b9bd-f6facc90ef65'
  },
  {
    name: 'Lexus CPO Sales Growth',
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/9489e48a-2cf4-47a9-9725-673dce1921f3?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '9489e48a-2cf4-47a9-9725-673dce1921f3'
  },
  {
    name: 'Daily Sales-No Sales Report - District',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/8757ea29-db8f-4b88-845d-2a9f5621c679?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '8757ea29-db8f-4b88-845d-2a9f5621c679'
  },
  {
    name: 'Daily Sales-No Sales Report - Dealer',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/32a1c4cc-e132-4f30-964f-bafe431dd7ba?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '32a1c4cc-e132-4f30-964f-bafe431dd7ba'
  },
  {
    name: 'Daily Sales Trend Report',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/b248c7aa-8e0a-4148-bf7f-1fad920644bc?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'b248c7aa-8e0a-4148-bf7f-1fad920644bc'
  },
  {
    name: 'National Daily Sales and Stock by Series',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/35deca67-5c43-4af9-9b4e-daacf7cb1ab4?directory_alias=tmnaqsadmin',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '35deca67-5c43-4af9-9b4e-daacf7cb1ab4'
  },
  {
    name: 'CYPY Sales by Series Report',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/936431449165/dashboards/ee7e2298-0d5a-4c67-acb8-f15ad1a3fa0f?directory_alias=tmnaqsadmin',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'ee7e2298-0d5a-4c67-acb8-f15ad1a3fa0f'
  },
  {
    name: 'ISR Best Months Insurance Sales',
    display: true,
    department: 'TFS',
    url: 'https://workout.test.toyota.com/Workout4/Reports/ReportContainer/MultiHierarchyReport?Screen_Item_Key=1891/redirectionFromNextGen=true',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '',
    isLegacyReport: true
  }

];


export const MockReportListResponseTEST = [
  {
    name: 'Daily AM Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/74d56b39-9f1c-49c3-bce5-4422ad343558?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '74d56b39-9f1c-49c3-bce5-4422ad343558'
  },
  {
    name: 'Dealer Sales Summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/d48c6dc5-3aa3-4461-bae5-75999d7c7790?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'd48c6dc5-3aa3-4461-bae5-75999d7c7790'
  },
  {
    name: 'MTD Dealer Sales Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/497030b2-d882-4da7-b1b1-39680c6a0763?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '497030b2-d882-4da7-b1b1-39680c6a0763'
  },
  {
    name: 'Sales Categories',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/5416d3d6-e8c3-4100-863f-0ddaeb7c70b1?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '5416d3d6-e8c3-4100-863f-0ddaeb7c70b1'
  },
  {
    name: 'Custom Sales Categories',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/caefa327-5637-42e6-89cf-86bf1d9b001d?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'caefa327-5637-42e6-89cf-86bf1d9b001d'
  },
  {
    name: 'Sales to Availability Review',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/a283d54f-95bf-4a74-8ef1-49922f904892?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'a283d54f-95bf-4a74-8ef1-49922f904892'
  },
  {
    name: 'Daily, MTD and YTD Sales & Objectives',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/3b726658-cfbd-4448-b362-2a58e23f0b26?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '3b726658-cfbd-4448-b362-2a58e23f0b26'
  },
  {
    name: 'Sales Summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/c9002b58-32bb-4d57-9857-ae1b28dc85ef?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'c9002b58-32bb-4d57-9857-ae1b28dc85ef'
  },
  {
    name: 'MTD Sales vs Stock % Region',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/2bc2fb2e-91e8-4168-913b-767361924607?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '2bc2fb2e-91e8-4168-913b-767361924607'
  },
  {
    name: 'Sales Market Share Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/b7adddd0-ffbf-4e46-ad7c-dd373f356e78?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'b7adddd0-ffbf-4e46-ad7c-dd373f356e78'
  },
  {
    name: 'CYPY Sales Analysis',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/cdaf6361-a87a-4308-9b76-5cb407a1b4a9?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'cdaf6361-a87a-4308-9b76-5cb407a1b4a9'
  },
  {
    name: 'Sales Categories Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/6b6615ac-ddc5-4cb1-8545-555ded328e5f?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '6b6615ac-ddc5-4cb1-8545-555ded328e5f'
  },
  {
    name: 'CYTD, PYTD Percent Change',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/521349ef-9223-4a4e-9636-8251944e7623?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '521349ef-9223-4a4e-9636-8251944e7623'
  },
  {
    name: 'MTD Dealer Truck Sales Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/f459e6ab-6676-402d-a2b2-bf8d4e2958cb?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'f459e6ab-6676-402d-a2b2-bf8d4e2958cb'
  },
  {
    name: 'Sales To Availability 4 Month Window',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/52a74dd6-fbbb-40cd-9f7d-d942cf3aff89?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '52a74dd6-fbbb-40cd-9f7d-d942cf3aff89'
  },
  {
    name: 'Yearly Tracking Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/29805407-8463-463c-a4de-bb14f1130fe1?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '29805407-8463-463c-a4de-bb14f1130fe1'
  },
  {
    name: 'Daily AM Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/96e92cfe-9260-4662-b0c4-fb5ff7403439?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '96e92cfe-9260-4662-b0c4-fb5ff7403439'
  },
  {
    name: 'National - Top 300 Ranking- Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/3f9a6e91-1a80-430a-b8ba-c7c0d79ec05f?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '3f9a6e91-1a80-430a-b8ba-c7c0d79ec05f'
  },
  {
    name: 'Entity Summary',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/6069c97f-3b9e-4ad7-b3fb-3d5e9efa131b?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '6069c97f-3b9e-4ad7-b3fb-3d5e9efa131b'
  },
  {
    name: 'Entity - Top 300  Ranking - Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/9c4bc187-748e-4805-9311-9282ec7d136d?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '9c4bc187-748e-4805-9311-9282ec7d136d'
  },
  {
    name: 'Public Entity CYPY Graph',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/8aa297e5-eeff-430a-861b-9abda2fcc404?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '8aa297e5-eeff-430a-861b-9abda2fcc404'
  },
  {
    name: 'Level 2 Entity summary',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/da6e9305-f933-4cb1-8fd4-5e748a8a17d5?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'da6e9305-f933-4cb1-8fd4-5e748a8a17d5'
  },
  {
    name: 'Sales To Availability Review Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/688aec10-9ef7-4516-b46b-3b74bad61cd8?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '688aec10-9ef7-4516-b46b-3b74bad61cd8'
  },
  // {
  //   name: 'MTD Sales vs Stock % Region Entity Report',
  //   display: true,
  //   department: 'Entity',
  //   url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Sales_vs_Stock_Entity_Report_Test?directory_alias=dxfw-test-workout-quicksight',
  //   frequency: 'Daily',
  //   dataAsOf: '--',
  //   dashboardId: 'Sales_vs_Stock_Entity_Report_Test'
  // },
  {
    name: 'Sales Market Share Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/c0cdde98-04da-40bf-9a45-1f4aa512305d?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'c0cdde98-04da-40bf-9a45-1f4aa512305d'
  },
  {
    name: 'Daily MTD And YTD Sales And Objectives Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Daily_MTD_And_YTD_Sales_And_Objectives_Entity_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Daily_MTD_And_YTD_Sales_And_Objectives_Entity_Report_Test'
  },
  {
    name: 'Sales Categories Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Sales_Categories_Entity_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Sales_Categories_Entity_Report_Test'
  },
  {
    name: 'Custom Sales Categories Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/be0f8587-4078-43f6-b647-c3407e8773d6?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'be0f8587-4078-43f6-b647-c3407e8773d6'
  },
  {
    name: 'TCUV Daily Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Daily_Sales_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Daily_Sales_Report_Test'
  },
  {
    name: 'TCUV Sales Ranking',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Sales_Ranking_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Sales_Ranking_Test'
  },
  {
    name: 'TCUV Daily AM Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Daily_AM_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Daily_AM_Report_Test'
  },
  {
    name: 'National - Top Ranking Dealers',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/National_Top_Ranking_Dealers_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'National_Top_Ranking_Dealers_Test'
  },
  {
    name: 'TCUV Source of Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Source_of_Sales_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Source_of_Sales_Report_Test'
  },
  {
    name: 'TCUV Data Dump Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Data_Dump_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Data_Dump_Report_Test'
  },
  {
    name: 'TCUV Sales by Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Sales_by_Series_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Sales_by_Series_Report_Test'
  },
  {
    name: 'TCUV Certifications By Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Certifications_By_Series_Report_CICD_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Certifications_By_Series_Report_CICD_Test'
  },
  {
    name: 'Toyota Certified Sales And Stock Summary Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Toyota_Certified_Sales_And_Stock_Summary_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Toyota_Certified_Sales_And_Stock_Summary_Report_Test'
  },
  {
    name: 'Lexus CPO Sales Summary', //Phase-5-test
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Lexus_CPO_Sales_Summary_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Lexus_CPO_Sales_Summary_Test'
  },
  {
    name: 'Lexus Pre-Owned Sales Ranking', 
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Lexus_Pre-Owned_Sales_Ranking_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Lexus_Pre-Owned_Sales_Ranking_Test'
  },
  {
    name: 'Lexus CPO Yearly Tracking',
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Lexus_CPO_Yearly_Tracking_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Lexus_CPO_Yearly_Tracking_Test'
  },
  {
    name: 'Lexus CPO Rolling Year Trend', 
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Lexus_CPO_Rolling_Year_Trend_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Lexus_CPO_Rolling_Year_Trend_Test'
  },
  {
    name: 'Daily Sales-No Sales Report - Region',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Daily_Sales_No_Sales_Report_Region_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Daily_Sales_No_Sales_Report_Region_Test'
  },
  {
    name: 'Lexus Daily Sales Summary',
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Lexus_Daily_Sales_Summary_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Lexus_Daily_Sales_Summary_Test'
  },
  {
    name: 'Lexus CPO Sales Growth', 
    display: true,
    department: 'PreOwned Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Lexus_CPO_Sales_Growth_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Lexus_CPO_Sales_Growth_Test'
  },
  {
    name: 'Daily Sales-No Sales Report - District',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Daily_Sales_No_Sales_Report_District_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Daily_Sales_No_Sales_Report_District_Test'
  },
  {
    name: 'Daily Sales-No Sales Report - Dealer',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Daily_Sales_No_Sales_Report_Dealer_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Daily_Sales_No_Sales_Report_Dealer_Test'
  },
  {
    name: 'Daily Sales Trend Report',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Daily_Sales_Trend_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Daily_Sales_Trend_Report_Test'
  },
  {
    name: 'National Daily Sales and Stock by Series', 
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/National_Daily_Sales_And_Stock_By_Series_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'National_Daily_Sales_And_Stock_By_Series_Test'
  },
  {
    name: 'CYPY Sales by Series Report',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/CYPY_Sales_By_Series_Report_Test?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'CYPY_Sales_By_Series_Report_Test'
  }
];

export const MockReportListResponseQA = [
  {
    name: 'Daily AM Report',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/25f365e7-76f0-451b-b01b-6047e91ee202?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '25f365e7-76f0-451b-b01b-6047e91ee202'
  },
  {
    name: 'Dealer Sales Summary',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/b63d9456-74a9-4d47-bd52-f6671ba9d3fc?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'b63d9456-74a9-4d47-bd52-f6671ba9d3fc'
  },
  {
    name: 'MTD Dealer Sales Ranking',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/0d6fce48-e2e8-4151-bf72-95cfe47fbbe7?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '0d6fce48-e2e8-4151-bf72-95cfe47fbbe7'
  },
  {
    name: 'Sales Categories',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/6a927ee9-82b8-4db7-82be-3cb95b8179db?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '6a927ee9-82b8-4db7-82be-3cb95b8179db',
  },
  {
    name: 'Custom Sales Categories',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/54799955-cac6-448b-b1ee-6b42b4662ae4?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '54799955-cac6-448b-b1ee-6b42b4662ae4'
  },
  {
    name: 'Sales to Availability Review',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/1b057138-0c40-4902-98e4-1ada786c67a4?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '1b057138-0c40-4902-98e4-1ada786c67a4'

  },
  {
    name: 'Daily, MTD and YTD Sales & Objectives',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Daily_MTD_And_YTD_Sales_And_Objectives_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Daily_MTD_And_YTD_Sales_And_Objectives_QA'
  },
  {
    name: 'Sales Summary',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/e5249e3e-1d6c-4696-8be6-00074487abe4?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'e5249e3e-1d6c-4696-8be6-00074487abe4'
  },
  {
    name: 'MTD Sales vs Stock % Region',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/MTD_Sales_VS_Stock_Percentage_Region_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'MTD_Sales_VS_Stock_Percentage_Region_QA'
  },
  {
    name: 'Sales Market Share Report',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/District_Sales_Market_Share_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'District_Sales_Market_Share_Report_QA'
  },
  {
    name: 'CYPY Sales Analysis',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/CYPY_Sales_Analysis_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'CYPY_Sales_Analysis_QA'
  },
  {
    name: 'Sales Categories Ranking',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Sales_Categories_Ranking_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Sales_Categories_Ranking_QA'
  },
  {
    name: 'CYTD, PYTD Percent Change',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/CYTD_PYTD_Percent_Change_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'CYTD_PYTD_Percent_Change_QA'
  },
  {
    name: 'MTD Dealer Truck Sales Ranking',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/MTD_Dealer_Truck_Sales_Ranking_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'MTD_Dealer_Truck_Sales_Ranking_QA'
  },
  {
    name: 'Sales To Availability 4 Month Window',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Sales_To_Availability_4_Month_Window_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Sales_To_Availability_4_Month_Window_QA'
  },
  {
    name: 'Yearly Tracking Report',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Yearly_Tracking_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Yearly_Tracking_Report_QA'
  },
  {
    name: 'Daily AM Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/db065872-94c0-494d-9dfd-8312c278a6d2?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'db065872-94c0-494d-9dfd-8312c278a6d2'
  },
  {
    name: 'National - Top 300 Ranking- Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/90dd7a5f-3fda-4535-a448-ad41339ead5b?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '90dd7a5f-3fda-4535-a448-ad41339ead5b'
  },
  {
    name: 'Entity Summary',
    display: true,
    department: 'Entity',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/606a977f-b6c4-4717-bc65-c3ff0fa3044d?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: '606a977f-b6c4-4717-bc65-c3ff0fa3044d'
  },
  {
    name: 'Entity - Top 300  Ranking - Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/25083f93-91c7-46fa-ba0b-1f973db661db?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: '25083f93-91c7-46fa-ba0b-1f973db661db'
  },
  {
    name: 'Public Entity CYPY Graph',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Public_Entity_CYPY_Graph_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Public_Entity_CYPY_Graph_QA'
  },
  {
    name: 'Level 2 Entity summary',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Level_2_Entity_Summary_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Entity',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Level_2_Entity_Summary_Report_QA'
  },
  {
    name: 'Sales To Availability Review Entity Report',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Sales_To_Availability_Review_Entity_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Sales_To_Availability_Review_Entity_Report_QA'
  },
  // {
  //   name: 'MTD Sales vs Stock % Region Entity Report',
  //   display: true,
  //   url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Sales_vs_Stock_Entity_Report_QA?directory_alias=dxfw-test-workout-quicksight',
  //   department: 'Entity',
  //   frequency: 'Daily',
  //   dataAsOf: '--',
  //   dashboardId: 'Sales_vs_Stock_Entity_Report_QA'
  // },
  {
    name: 'Sales Market Share Entity Report',
    display: true,
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Entity_Sales_Market_Share_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Entity_Sales_Market_Share_Report_QA'
  },
  {
    name: 'Sales Categories Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Sales_Categories_Entity_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Sales_Categories_Entity_Report_QA'
  },
  {
    name: 'Custom Sales Categories Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Custom_Sales_Categories_Entity_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Custom_Sales_Categories_Entity_Report_QA'
  },
  {
    name: 'Daily MTD And YTD Sales And Objectives Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Daily_MTD_And_YTD_Sales_And_Objectives_Entity_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Daily_MTD_And_YTD_Sales_And_Objectives_Entity_Report_QA'
  },
  {
    name: 'TCUV Daily Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Daily_Sales_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Daily_Sales_Report_QA'
  },
  {
    name: 'TCUV Sales Ranking',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Sales_Ranking_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Sales_Ranking_QA'
  },
  {
    name: 'TCUV Daily AM Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Daily_AM_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Daily_AM_Report_QA'
  },
  {
    name: 'National - Top Ranking Dealers',
    display: true,
    department: 'Sales',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/National_Top_Ranking_Dealers_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'National_Top_Ranking_Dealers_QA'
  },
  {
    name: 'TCUV Source of Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Source_of_Sales_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Source_of_Sales_Report_QA'
  },
  {
    name: 'TCUV Data Dump Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Data_Dump_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Data_Dump_Report_QA'
  },
  {
    name: 'TCUV Sales by Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Sales_by_Series_Report_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Sales_by_Series_Report_QA'
  },
  {
    name: 'TCUV Certifications By Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/TCUV_Certifications_By_Series_Report_CICD_QA?directory_alias=dxfw-test-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Certifications_By_Series_Report_CICD_QA'
  },
  {
    name: 'Toyota Certified Sales And Stock Summary Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/976200942468/dashboards/Toyota_Certified_Sales_And_Stock_Summary_Report_QA?directory_alias=dxfw-test-workout-quicksigh',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Toyota_Certified_Sales_And_Stock_Summary_Report_QA'
  }
];

export const MockReportListResponsePROD = [
  {
    name: 'Daily AM Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Daily_AM_Report?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Daily_AM_Report'
  },
  {
    name: 'Dealer Sales Summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Toyota_Dealer_Summary?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Toyota_Dealer_Summary'
  },
  {
    name: 'MTD Dealer Sales Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/MTD_Dealer_Sales_Ranking?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'MTD_Dealer_Sales_Ranking'
  },
  {
    name: 'Sales Categories',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_Categories?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Sales_Categories'
  },
  {
    name: 'Custom Sales Categories',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Custom_Sales_Categories?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Custom_Sales_Categories'
  },
  {
    name: 'Sales to Availability Review',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_To_Availability_Review?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Sales_To_Availability_Review'
  },
  {
    name: 'Daily, MTD and YTD Sales & Objectives',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Daily_MTD_And_YTD_Sales_And_Objectives?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Daily_MTD_And_YTD_Sales_And_Objectives'
  },
  {
    name: 'Sales Summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_Summary?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Sales_Summary'
  },
  {
    name: 'MTD Sales vs Stock % Region',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/MTD_Sales_VS_Stock_Percentage_Region?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'MTD_Sales_VS_Stock_Percentage_Region'
  },
  {
    name: 'Sales Market Share Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/District_Sales_Market_Share_Report?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'District_Sales_Market_Share_Report'
  },
  {
    name: 'CYPY Sales Analysis',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/CYPY_Sales_Analysis?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'CYPY_Sales_Analysis'
  },
  {
    name: 'Sales Categories Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_Categories_Ranking?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Sales_Categories_Ranking'
  },
  {
    name: 'CYTD, PYTD Percent Change',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/CYTD_PYTD_Percent_Change?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'CYTD_PYTD_Percent_Change'
  },
  {
    name: 'MTD Dealer Truck Sales Ranking',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/MTD_Dealer_Truck_Sales_Ranking?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'MTD_Dealer_Truck_Sales_Ranking'
  },
  {
    name: 'Sales To Availability 4 Month Window',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_To_Availability_4_Month_Window?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Sales_To_Availability_4_Month_Window'
  },
  {
    name: 'Yearly Tracking Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Yearly_Tracking_Report?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Sales',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Yearly_Tracking_Report'
  },
  {
    name: 'Daily AM Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Daily_AM_Entity_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Daily_AM_Entity_Report'
  },
  {
    name: 'National - Top 300 Ranking- Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/National_Top_Ranking_Entity_Dealers?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'National_Top_Ranking_Entity_Dealers'
  },
  {
    name: 'Entity Summary',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Entity_Summary?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Entity_Summary'
  },
  {
    name: 'Entity - Top 300 Ranking - Entity Dealers',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Entity_Top_Ranking_Entity_Dealers?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Entity_Top_Ranking_Entity_Dealers'
  },
  {
    name: 'Public Entity CYPY Graph',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Public_Entity_CYPY_Graph?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Public_Entity_CYPY_Graph'
  },
  {
    name: 'Level 2 Entity summary',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Level_2_Entity_Summary_Report?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Entity',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Level_2_Entity_Summary_Report'
  },
  {
    name: 'Sales To Availability Review Entity Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_To_Availability_Review_Entity_Report?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Sales_To_Availability_Review_Entity_Report'
  },
  // {
  //   name: 'MTD Sales vs Stock % Region Entity Report',
  //   display: true,
  //   url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_vs_Stock_Entity_Report?directory_alias=dxfw-prod-workout-quicksight',
  //   department: 'Entity',
  //   frequency: 'Daily',
  //   dataAsOf: '--',
  //   dashboardId: 'Sales_vs_Stock_Entity_Report'
  // },
  {
    name: 'Sales Market Share Entity Report',
    display: true,
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Entity_Sales_Market_Share_Report?directory_alias=dxfw-prod-workout-quicksight',
    department: 'Entity',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Entity_Sales_Market_Share_Report'
  },
  {
    name: 'Sales Categories Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Sales_Categories_Entity_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Sales_Categories_Entity_Report'
  },
  {
    name: 'Custom Sales Categories Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Custom_Sales_Categories_Entity_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Custom_Sales_Categories_Entity_Report'
  },
  {
    name: 'Daily MTD And YTD Sales And Objectives Entity Report',
    display: true,
    department: 'Entity',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Daily_MTD_And_YTD_Sales_And_Objectives_Entity_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'Daily_MTD_And_YTD_Sales_And_Objectives_Entity_Report'
  },
  {
    name: 'TCUV Daily Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/TCUV_Daily_Sales_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Daily_Sales_Report'
  },
  {
    name: 'TCUV Sales Ranking',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/TCUV_Sales_Ranking?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Sales_Ranking'
  },
  {
    name: 'TCUV Daily AM Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/TCUV_Daily_AM_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'TCUV_Daily_AM_Report'
  },
  {
    name: 'National - Top Ranking Dealers',
    display: true,
    department: 'Sales',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/National_Top_Ranking_Dealers?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'National_Top_Ranking_Dealers'
  },
  {
    name: 'TCUV Source of Sales Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/TCUV_Source_of_Sales_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Source_of_Sales_Report'
  },
  {
    name: 'TCUV Data Dump Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/TCUV_Data_Dump_Report?directory_alias=dxfw-prod-workout-quicksigh',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Data_Dump_Report'
  },
  {
    name: 'TCUV Sales by Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/TCUV_Sales_by_Series_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Sales_by_Series_Report'
  },
  {
    name: 'TCUV Certifications By Series Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/TCUV_Certifications_By_Series_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Monthly',
    dataAsOf: '--',
    dashboardId: 'TCUV_Certifications_By_Series_Report'
  },
  {
    name: 'Toyota Certified Sales And Stock Summary Report',
    display: true,
    department: 'Used Vehicles',
    url: 'https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/405040360578/dashboards/Toyota_Certified_Sales_And_Stock_Summary_Report?directory_alias=dxfw-prod-workout-quicksight',
    frequency: 'Daily',
    dataAsOf: '--',
    dashboardId: 'Toyota_Certified_Sales_And_Stock_Summary_Report'
  }
]; 