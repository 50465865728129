import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { WorkoutSalesModule } from './modules/workout-sales/workout-sale.module';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { EmptyRouteComponent } from './component/empty-route/empty-route.component';
import { AppComponent } from './app.component';
import { PipeModule } from './modules/pipes/pipe.module';
import { CustomReportModule } from './modules/custom-reports/custom-reports.module';
import { BulkUploadComponent } from './modules/workout-sales/components/bulk-upload/bulk-upload.component';
import { PartDepartmentModelComponent } from './modules/custom-reports/reports/part-department-model/part-department-model.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    SpinnerComponent,
    BulkUploadComponent,
    PartDepartmentModelComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    WorkoutSalesModule,
    CustomReportModule,
    PipeModule,
    MaterialModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
