import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MockReportListResponseDEV, MockReportListResponsePROD, MockReportListResponseQA, MockReportListResponseTEST } from '../mock/mock';
import { IActivityParams, IConfig } from '../../../interface/common.interface';
import { environment } from '../../../../environments/environment';
import { MockCustomReportListResponseDEV } from '../../custom-reports/mock/mock';

@Injectable({
  providedIn: 'root'
})
export class WorkoutReportsCoreService {

  private readonly apiConstants: any = {
    getReportListAPI : 'useradmin/report-list',
    getDashboardUrlAPI: 'useradmin/dashboard-url',
    getActivityUrlAPI: 'useradmin/add-activity-log'
  };

  private currentEnv: IConfig = environment;

  constructor(
    private readonly httpClient: HttpClient
  ) { }

  public getMockReportList(env: IConfig, userId: string,  menuId: number): Observable<any> {
 
        if (env.disableAPICall) {
          let mockResponse = [];
          if (env !== null){
            if (env.name === 'LOCAL' || env.name === 'DEV') {
              mockResponse = MockReportListResponseDEV;
            } else if(env.name === 'TEST') {
              mockResponse = MockReportListResponseTEST;
            } else if(env.name === 'QA') {
              mockResponse = MockReportListResponseQA;
            } else if (env.name === 'PROD') {
              mockResponse = MockReportListResponsePROD;
            }
          }
          return of(mockResponse);
        } else {
          const apiUrl = `${env.API_END_POINT}/${this.apiConstants.getReportListAPI}/${userId}/${menuId}`;
          return this.httpClient.get(apiUrl);
        }
    }
  
  public getDashboardUrl(emailId: string, dashboardId: string): Observable<any> {
    const apiDashboardUrl = `${this.currentEnv.API_END_POINT}/${this.apiConstants.getDashboardUrlAPI}/${dashboardId}/${emailId}`;
    return this.httpClient.get(apiDashboardUrl);
  }

  public addActivityLogs(activityParams: IActivityParams): Observable<any> {
    const apiUrl = `${this.currentEnv.API_END_POINT}/${this.apiConstants.getActivityUrlAPI}`;
    return this.httpClient.post(apiUrl, activityParams);
  }

  public getIPAddress(): Observable<any> {
    const apiUrl = 'https://freegeoip.net/json/?callback';
    return this.httpClient.get(apiUrl);
  }
}
