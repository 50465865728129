<section class="part-department-model">
  <div class="part-department-model__header">
    <h5 class="part-department-model__header__title">Account Information</h5>
    <div class="part-department-model__header__close-icon">
      <mat-icon (click)="closeModal()">close</mat-icon>
    </div>
  </div>
  <div class="part-department-model__content">
    <div class="part-department-model__content__header">
      <p class="part-department-model__content__header__title">ACCOUNT:P&A CUST MECH/TC/TC+ /TIRES SALES</p>
      <div class="part-department-model__content__header__icons">
        <mat-icon class="part-department-model__content__header__icons__size">edit</mat-icon>
        <mat-icon class="part-department-model__content__header__icons__size">print</mat-icon>

      </div>
    </div>
    <div class="part-department-model__content__description">
      <h5 class="part-department-model__content__description__title">DEFINITION</h5>
      <p class="part-department-model__content__description__text">Inventory</p>
    </div>

    <div class="part-department-model__content__description">
      <h5 class="part-department-model__content__description__title">CONSEQUENCES</h5>
      <p class="part-department-model__content__description__text">Inventory</p>
    </div>

    <div class="part-department-model__content__description">
      <h5 class="part-department-model__content__description__title">RESOLUTIONS</h5>
      <p class="part-department-model__content__description__text">Inventory</p>
    </div>

    <div class="part-department-model__content__description">
      <h5 class="part-department-model__content__description__title">BENCHMARK</h5>
      <p class="part-department-model__content__description__text">B9659</p>
    </div>
  </div>

  <div class="part-department-model__footer">
    <button mat-raised-button color="primary" class="part-department-model__footer__closebtn"
      (click)="closeModal()">Close</button>
  </div>
</section>
