import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-finance-disclaimer-model',
  templateUrl: './finance-disclaimer-model.component.html',
  styleUrls: ['./finance-disclaimer-model.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinanceDisclaimerModelComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<FinanceDisclaimerModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data,

  ) { 
}

  public ngOnInit() { 
  }

  acceptModal(): void {
    this.dialogRef.close({event:true});
  }

   
  //In component calling the dialog component.
  
  declineModal(): void {
    this.dialogRef.close({event:false});
  }
}
