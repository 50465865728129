<div class="dxfw-grid-title">
  <div class="grid-header">
    <span class="title pr-3"> {{headerName}}</span>
    <ul class="search-chip">
      <ng-container *ngFor="let search of searchObject;let i=index;">
          <li *ngIf="search.value !=''">
              {{search.value}} <em class="tmi tmi-close" (click)="removeFilter(i, search)"></em>
          </li>
      </ng-container>
  </ul>
  <div class="right-btn">
      <!-- <a class="mr-1" *ngIf="displayRegisterUser" href="https://us-east-1.quicksight.aws.amazon.com/sn/auth/signin?enable-sso=1"> Quicksight User Registration </a> -->
      <button class="primary-button small-button" *ngIf="searchObject.length !== 0" (click)="removeAllFilters()">Clear All</button>
    </div>
  </div>
  <div>
    <mat-sidenav-container autosize>

      <mat-sidenav mode="side" [(opened)]="openSideNav" disableClose>
        <app-report-side-filter></app-report-side-filter>
      </mat-sidenav>
  
      <mat-sidenav-content>
     <app-report-content *ngIf ='!hideDashboardReports'  [menuId] = "menuId" [isCustomReport] = "isCustomReports" [SelectedGridFilter]="searchObject" (updateSearch)="updateSearchObj($event)"></app-report-content>
          <div *ngIf ='hideDashboardReports' class='dxfw-customreport-no-page-found'>
            Page is under construction!!!
          </div>
        </mat-sidenav-content>
  </mat-sidenav-container>
  </div>
</div>