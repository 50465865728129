import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { HIERARCHY_TYPE, reportUrlType } from 'src/app/constants/constants';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CRGridSearch } from '../../../workout-sales/interfaces/custom-reports.interface';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';
import { LexusDealerAtGlanceReport } from '../reportConstants';
import * as _ from 'lodash';
import { HrcyMock } from '../../mock/mock';

@Component({
  selector: 'app-lexus-dealer-at-glance',
  templateUrl: './lexus-dealer-at-glance.component.html',
  styleUrls: ['./lexus-dealer-at-glance.component.scss']
})
export class LexusDealerAtGlanceComponent implements OnInit {

  @Output() updateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  public displayColumsForTopTenDealerDealer: string[] = [];
  public lexusDealerAtGlanceReportDataSource = new MatTableDataSource([]);
  public currentTabIndex: number = 0;
  public lexusDealerAtGlancejsonObj;
  public isExpansionEnabled: boolean = false;
  public forgerockUrl: string = '';
  public subscription: Subscription = new Subscription();
  public selectedTabIndex = [0];
  private previousFilteredValue;
  public sources: Observable<any>[] = [];
  public selectedFilter = [];
  reportId: any;
  isFilterSame: boolean;
  previousFilter: any;
  selectedHierachy: any;
  public obj = Object.values;
  isProfileTable: boolean = false;
  selectedDateFilter: string;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private readonly customReportsService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    this.getLexusDealerAtGlanceReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }

  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    this.sources = [];
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1 && this.selectedFilter.length > 0 && this.currentTabIndex !== 4 && this.currentTabIndex !== 5) {
      this.selectedTabIndex.push(tabIndex);
      this.filterService.setSelectedValues(this.selectedFilter);
    } else {
      this.spinnerService.hideSpinner();
    }
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource(tabIndex) {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.selectedDateFilter = obj['monthYear'];
    const dateObj = getMonthAndYear(obj['monthYear']);
    const filterObj = obj['dealer'];
    const levelName = obj['hierarchy']?.name;
    const levelId = obj['hierarchy']?.id;
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      hierarchy_level_type_rcid: levelId,
      hierarchy_level_member_rcid: filterObj.MEMBER_CODE,
      view_id: this.getViewId(tabIndex, levelName)
    }
    if (tabIndex === 0 || tabIndex === 1 || tabIndex === 2 || tabIndex === 3 || tabIndex === 4 || tabIndex === 5) {
      this.sources = [this.customReportsApiService.getLexusDAAGForLexus(requestObj).pipe(this.customReportsService.handleError()?.bind(this))];
    }
    else if (tabIndex === 6 && levelId === HIERARCHY_TYPE.DEALER) {
      requestObj['hierarchy'] = filterObj.MEMBER_CODE,
        this.sources = [this.customReportsApiService.getLexusDAAGForDealerProfile(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
        ]
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
    return this.sources;

  }


  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (filteredValues) => {
          this.spinnerService.displaySpinner();
          if (filteredValues?.length > 0) {
            this.selectedFilter = filteredValues;
            this.isFilterSame = _.isEqual(this.selectedFilter, this.previousFilter);
            if (this.isFilterSame === false) {
              this.selectedTabIndex = [];
              this.selectedTabIndex.push(this.currentTabIndex);
            }
            if (this.lexusDealerAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
              this.getLexusDealerAtGlanceReport(); //initialise to empty object
              this.assignTabsAccordingToHrchy();
            }
            this.mapResponse();
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  assignTabsAccordingToHrchy() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const levelId = obj['hierarchy']?.id;
    if (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL) {
      const filteredTabList = this.lexusDealerAtGlancejsonObj?.tabList.filter((tab, i) => (i !== 3 && i !== 7));
      this.lexusDealerAtGlancejsonObj.tabList = filteredTabList
    } else if (levelId === HIERARCHY_TYPE.DEALER) {
      const filteredTabList = this.lexusDealerAtGlancejsonObj?.tabList.filter((tab, i) => i !== 0);
      this.lexusDealerAtGlancejsonObj.tabList = filteredTabList
    }
  }

  public async mapResponse() {
    let currentTableResponse;
    const obj = getObjFromFilteredArray(this.selectedFilter);
    let levelId = obj['hierarchy']?.id;

    if (this.currentTabIndex === 6) {
      if (levelId === HIERARCHY_TYPE.DEALER) {
        await this.getTabDataSource(this.currentTabIndex);
      } else if (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL) {
        this.tabChange(this.currentTabIndex - 1);  // to update tabIndex if currentTeb is 6 and user changes the hrchy to region/national then last index ahould be 5 instead of 6
      }
    } else {
      await this.getTabDataSource(this.currentTabIndex);
    }
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200 && this.currentTabIndex !== 6) {
          this.customReportsService.setPdfExcelIcons(true);
          let columnHeaderName = this.obj(resp[0]?.body[0]?.reportHeaderData[1][0]);
          let tableData = resp[0]?.body[0]?.reportData;
          let sectionTitle = resp[0]?.body[0]?.reportHeaderData[1][0]?.SECTIONTITLE;
          let reportHeaderData = resp[0]?.body[0]?.reportHeaderData[0][0];
          if (this.currentTabIndex === 0) {
            if (levelId === HIERARCHY_TYPE.DEALER) {
              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.MTDTITLE]: 4 }];
              const datasourceName = 'LEXUSCompleteMaintainDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            } else if (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL) {
              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 2 }];
              const datasourceName = 'LEXUSPartsDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            }
          }
          if (this.currentTabIndex === 1) {
            if (levelId === HIERARCHY_TYPE.DEALER) {
              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 3 }];
              const datasourceName = 'LEXUServiceRetentionDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            } else if (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL) {
              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 2 }];
              const datasourceName = 'LEXUSCompleteMaintainDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            }
          }
          if (this.currentTabIndex === 2) {
            if (levelId === HIERARCHY_TYPE.DEALER) {
              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 2 }];
              const datasourceName = 'LEXURoadsideDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            } else if (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL) {
              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 2 }];
              const datasourceName = 'LEXUServiceRetentionDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            }
          }
          if (this.currentTabIndex === 3) {
            if (levelId === HIERARCHY_TYPE.DEALER) {
              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 4 }];
              const datasourceName = 'LEXUSVolumeDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            } else if (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL) {

              const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 2 }];
              const datasourceName = 'LEXUSVolumeDS';
              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
            }
          }
          if (this.currentTabIndex === 4) {
            const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 4 }];
            const datasourceName = 'LEXUSServiceCapacityDS';
            currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
          }
          if (this.currentTabIndex === 5) {
            const tableHeader = [{ [reportHeaderData.SECTIONTITLE]: 1, ' ': 1, [reportHeaderData.HISTORYTITLE]: 6, [reportHeaderData.YTDTITLE]: 4 }];
            const datasourceName = 'LEXUSProgramsDS';
            currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, sectionTitle }];
          }
        }
        if (this.currentTabIndex === 6 && levelId === HIERARCHY_TYPE.DEALER) {
          if (resp.length > 0 && resp[0].status === 200) {
            const obj = [{
              "datasourceName": "profileDS",
              "tableData": resp[0]?.body,
              "isRegionalTable": false,
            }];
            currentTableResponse = obj;
          }
        }
        if (currentTableResponse) {
          this.lexusDealerAtGlancejsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
        }
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }


  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription?.unsubscribe();
    this.customReportsService.setPdfExcelIcons(false);

  }

  public getLexusDealerAtGlanceReport() {
    let tabList = [
      {
        tabName: 'Parts',
        datasource: []
      },
      {
        tabName: 'Lexus Complete Maintenance Care (LCMC)',
        datasource: []
      },
      {
        tabName: 'Service Retention',
        datasource: []
      },
      {
        tabName: 'Roadside',
        datasource: []
      },
      {
        tabName: 'Volume',
        datasource: []
      },
      {
        tabName: 'Service Capacity',
        datasource: []
      },
      {
        tabName: 'Programs',
        datasource: []
      },
      {
        tabName: 'Dealer Profile',
        datasource: []
      }
    ];
    this.lexusDealerAtGlancejsonObj = {
      tabList: tabList
    }
  }

  public getViewId(tabIndex: number, level_type) {
    let viewIds = [];
    if (level_type === HrcyMock[0].name) {
      viewIds = ['lcmc_info', 'service_retention_info', '', 'volume_info', '', ''];
    } else if (level_type === HrcyMock[2].name || level_type === HrcyMock[3].name) {
      viewIds = ['parts_info', 'lcmc_info', 'service_retention_info', 'volume_info', '', ''];
    }
    return `${level_type?.toLowerCase()}_${viewIds[tabIndex]}`;
  }

  public displayProfileSection() {
    if (this.currentTabIndex === 6) {
      this.isProfileTable = true;

    } else {
      this.isProfileTable = false;
    }
  }

  public hideHeaderColumns(reportHeaderColumns: any) {
    const reportHeaders = _.filter(reportHeaderColumns, x => !(x.includes(x.X_UNITS_FLG) && x.includes(x.X_ACCT_NO)));
    return reportHeaders;
  }

}