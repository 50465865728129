import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssetUrlPipe } from './asset-url.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
export { AssetUrlPipe } from './asset-url.pipe';
import { dateFormatPipe } from './formated-date.pipe';
import { dataFormatPipe } from './data-format.pipe';


@NgModule({
    declarations: [
        AssetUrlPipe,
        SafeUrlPipe,
        dateFormatPipe,
        dataFormatPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AssetUrlPipe,
        SafeUrlPipe,
        dateFormatPipe,
        dataFormatPipe
    ]
  })
  export class PipeModule { }
    