import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private readonly showSpinner: Subject<boolean> = new Subject();
  public spinnerSize = 'large';

  constructor() { }

  public getSpinner$(): Observable<boolean> {
      return this.showSpinner;
  }

  public displaySpinner(): void {
      this.showSpinner.next(true);
  }

  public hideSpinner(): void {
      this.showSpinner.next(false);
  }
}
