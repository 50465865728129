import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
import { IReportList } from '../../modules/workout-sales/interfaces/workout-reports.interface';
import { WorkoutReportsApiService } from '../../modules/workout-sales/services/workout-reports-api.service';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

@Component({
  selector: 'app-quicksight-reports',
  templateUrl: './quicksight-reports.component.html',
  styleUrls: ['./quicksight-reports.component.scss']
})
export class QuicksightReportsComponent implements OnInit {

  public dashboard: any;
  public reportObj: IReportList;
  public quicksight_height: string = `${window.innerHeight - 50}px`;
  public embedUrl: string = '';
  public forgerockUrl: string = '';

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private readonly workoutReportsAPIService: WorkoutReportsApiService
  ) {
  }

  // ngAfterViewInit(): void {
  //   const height = window.innerHeight - 50;
  //   this.quicksight_height = `${height}px`;
  // }

  ngOnInit(): void {
    // new code
    this.spinnerService.displaySpinner();
    const reportObj = this.workoutReportsAPIService.getReportInfo();   
    this.reportObj = JSON.parse(reportObj);
    if (this.reportObj && this.reportObj.dashboardid) {
      this.getDashboardURL();
    } else {
      this.goBack();
    }
  }

  public goBack(): void {
    this.router.navigate(['/workout/operational']);
    
  }

  public getDashboardURL(): void {
    this.workoutReportsAPIService.getDashboardUrl(this.reportObj.dashboardid).subscribe(async (data) => {
      const embedUrl = data['body'].EmbedUrl;
      var containerDiv = document.getElementById("dashboardContainer") || '';
      const frameOptions = {
        url: embedUrl,
        container: containerDiv,
        resizeHeightOnSizeChangedEvent: true,
      }
      const embeddingContext = await createEmbeddingContext();
      this.dashboard = embeddingContext.embedDashboard(frameOptions);

      setTimeout(() => {
        this.spinnerService.hideSpinner();
      }, 3000);
    });
  }

}
