import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterDateRangeModule } from '../filter-date-range/filter-date-range.module';
import { FilterDateRangeComponent } from '../filter-date-range/filter-date-range.component';
import { DropDownListComponent } from '../drop-down-list/drop-down-list.component';
import { DropDownListModule } from '../drop-down-list/drop-down-list.module';
import { MultiSelectListModule } from '../multi-select-list/multi-select-list.module';
import { MultiSelectListComponent } from '../multi-select-list/multi-select-list.component';

@NgModule({
  exports: [FilterDateRangeComponent,DropDownListComponent,MultiSelectListComponent],
  imports: [
    CommonModule,
    FilterDateRangeModule,
    DropDownListModule,
    MultiSelectListModule
  ]
})
export class SideNavModule { }
