/* map all lexus po snapshot executive summary report header
    first is tabs name
    second is each table header with the columnspan */
export const LexusPOSnapShotReport = {
  lexusPOSnapShotTabNames: [
    "YTD Sales Growth / MTD Sales Growth",
    "YTD Top 10 Sales Dealers Nationally / Area Sales as % Nation",
    "MTD Best-Ever / Market Share / LFS Dealer Direct Grounded vs Purchased / Total Lexus PO Reporting Discrepancies",
    "Competitive Sales Analysis / Business Management / CSI/Elite",
    "Looking Forward",
    "Area Objectives / Incentives / Announcements"
  ],
  YTDHeader: [{
    "YTD Sales Growth - 4 Year Trend": '11'
  }],
  MTDHeader: [{
    "MTD Sales Growth - 4 Year Trend": '7'
  }],
  YTDSubHeader: [{
    " ": '1',
    "CERTIFIED PRE-OWNED": '5',
    "TOTAL LEXUS PRE-OWNED": '5'
  }],
  MTDSubHeader: [{
    " ": '1',
    "CERTIFIED PRE-OWNED": '3',
    "Lexus Total Pre-Owned": '3'
  }],

  YTDTop10SalesHeader: [{
    "YTD Top 10 Sales Dealers Nationally": '11'
  }],
  YTDTop10SalesSubHeader: [{
    "CERTIFIED PRE-OWNED": '4',
    "TOTAL LEXUS PRE-OWNED": '4'
  }],

  MTDBestRecSubHeader: [{
    " ": '1',
    "CPO": '2',
    "TOTAL LEXUS PO": '2'
  }],
  MTDBestRecHeader: [{
    "MTD BEST-EVER RECORDS": '5'
  }],
  MarketShareHeader: [{
    "MARKET SHARE": '3'
  }],
  competativeSalesAnalysisHeader: [{
    "COMPETITIVE SALES ANALYSIS": '4'
  }],
  competativeSalesAnalysisSubHeader: [{
    " ": '1',
    "CPO": '2',
    "SALES PER OUTLET": '1'
  }],
  LexusPOAreaSalesPercMTDSalesDataHeader:[{
    "AREA SALES AS % NATION" : '5',
    "P.O.:NEW RATIOS" : '2'
  }],
  lexusPOGroundedvsPurchasedHeader : [{
    "LFS Dealer Direct Grounded vs Purchased - N/A" : '6'
  }],
  IncentivesHeader : 'Incentives',
  AnnouncementsHeader : 'Announcements'
}

export const PartDepartmentAnalysisReport = {
  partDepartmentAnalysisTabNames: [
    "Parts",
  ],
  YTDHeader: [{
    "UOT2 - 24073": '22'
  }]
}
export const ServiceDepartmentAnalysisReport = {
  serviceDepartmentAnalysisTabNames: [
    "1 SAMPLE_01",
  ],
  YTDHeader: [{
    // "UOT2 - 24073": '23'
  }]
}
export const LexusSalesAtGlanceReport = {
  LexusSalesAtGlanceTabNames: [
    "Scores",
    "Sales",
    "Sales By Model",
    "Market Share",
    "Gross Profit",
    "Dealer Profile"
  ],
  ScoresHeader:[{
    "GUEST SATISFACTION":'2',
    "DEALER 6-MONTH HISTORY":'6',
    "YEAR TO DATE": '4',
  }],
  ScoresHeader_1:[{
    "SALES-VOLUME": '2',
    "DEALER 6-MONTH HISTORY": '6',
    "YEAR TO DATE": '4',
  }],
  SalesHeader:[{
    "SALES": '2',
    "DEALER 6-MONTH HISTORY": '6',
    "MTD": '2',
    "YTD ": '2',
    "UNITS +/-ELITE": ''
  }],
  MarketShareHeader:[{
    "MARKET SHARE": '1',
        "ROLLING 12 MONTHS": '3',
        "YTD": '3',
        "YTD %CHANGE": '3',
        "SALES EFFICIENCY": '',
        "MARKET LEADER": '',
        "UNITS +/- LEADER": ''
  }],
  GrossProfitHeader:[{
    "GROSS PROFIT": 1,
    "GROSS PROFIT BEFORE INCENTIVES $PUR/L": 6,
    "GROSS PROFIT AFTER INCENTIVES $PUR/L": 6,
  }],
  GrossProfitSubHeader:[{
    "             ": 1,
    "MTD": 3,
    "YTD": 3,
    "MTD ":3,
    "YTD ":3,
  }]
}
export const ToyotaDealerAtGlanceReport = {
  ToyotaDealerAtGlanceTabNames: [
    "Profile",
    "Key Dealership Insights",
    "Toyota Loyalty And Engagement",
    "Customer Handling",
    "Technician Skill Level Guidelines/Service",
     "Service And Parts Sales",
     "Parts And Accessory Purchases",
     "Customer Connection"
  ],
  KeyDealershipInsightHeader:[{
  "KEY DEALERSHIP INSIGHTS":'11',
  }],
  KeyDealershipInsightSubHeader:[{
   "CUSTOMER COMMUNICATION PROGRAMS": '4',
   "CUSTOMER EXPERIENCE PROGRAM PARTICIPATION": '4'
  }],
  ToyotaLoyaltyHeader:[{
    "TOYOTA LOYALTY and ENGAGEMENT":'11'
  }],
  ToyotaLoyaltySubHeader:[{
    "TLE Efficiency(TLEe) 12 MONTH ROLLING": '3',
    "MAR 2024":'3'
  }],
  CustHandlingHeader: [{
    "CUSTOMER HANDLING":'11'
  }],
   CustHandlingSubHeader: [{
    " ": '2',
    "OVERDUE": '2',
    "NO-RESPONSE": '2',
    "CLOSED EXTENSIONS": '2',
    "  ":'1'
   }],
   TechnicianSkillHeader:[{
    "TECHNICIAN SKILL LEVEL GUIDELINES":'11'
   }],
  ServicePartsHeader: [{
    "SERVICE AND PARTS SALES": '11'
  }],
  ServicePartsSubHeader: [{
    " ": '1',
    "MTD": '3',
    "YTD":'2',
    "YTD%INCREASE/DECREASE":'3'
  }],
  PartsHeader: [{
    "PARTS AND ACCESSORY PURCHASES" :'11'
  }],
  PartsHeader1: [{
    "Gross General Parts Purchases (Does not exclude returns,SSC,etc...) " :'11'
  }],
  PartsSubHeader: [{
    " ": '1',
    "MTD": '3',
    "YTD":'2',
    "% OF PRIOR YTD":'3'
  }],
  CustomerConnectionHeader:[{
    "CUSTOMER CONNECTION": '11'
  }]
}

export const PsFinancialReport = {
  psFinanceHeader : [{
    "CURRENT MONTH": '4',
    "YEAR TO DATE": '3',
  }]
}

export const TCUVDealerAtGlanceReport = {
  TCUVDealerAtGlanceTabNames: [
    "Profile",
    "NVS",
    "Financial statement"
  ],
  NVSHeader: [{
    "NVS(06/01/2021)": '10'
  }],
  FinancialHeader: [{
    "FINANCIAL STATEMENT": '10'
  }],
  NVSSubHeader: [{
    " ": '1',
    "MONTH TO DATE": '3',
    "YEAR TO DATE": '3',
    "% CHANGE": '3'
  }],
  FinancialStatementSubHeader: [{
    " ": '1',
    "MONTH TO DATE": '3',
    "YEAR TO DATE": '3',
    "% CHANGE": '3'
  }],
  SeriesReportRecHeader: [{
    "SERIES REPORT": '1',
    "SALES": '2',
    "CERTIFICATION": '2'
  }],
  SourceOfSalesSubHeader: [{
    " ": '1',
    "YTD": '2',
    "MTD": '2'
  }],
  SourceOfSalesHeader: [{
    "SOURCE Of SALES": '5'
  }],
  ComplainceReportSubHeader: [{
    "SAME DAY PROCESSING": '2',
    "LATE REPORTING": '3'
  }],
  ComplainceReportHeader: [{
    "COMPLAINCE REPORT": '5'
  }],
  agedUnitsHeader: [{
    "AGED UNITS": '3'
  }],
  financialHeader : [{
    "FINANCIAL STATEMENT (JAN 2001)" : "10"
  }],
  financialSubHeader : [{
    " ": '1',
    "MONTH TO DATE": "3",
    "YEAR TO DATE	": "3",
    "% CHANGE" : "3"
  }],
}

export const DisplayValues = {
    "NATLAVG":"National",
    "T_YEAR" : "Year",
    "C_YEAR" : "Year",
    "C_TOTAL_SALES" :"Total sales",
    "T_TOTAL_SALES" :"Total sales",
    "C_RANK" : "Rank",
    "T_RANK" : "Rank",
    "C_DEALER" : "Dealer",
    "T_DEALER" : "Dealer",
    "C_AREA" : "Area",
    "T_AREA" : "Area",
    "PERCENTAGE_TOTAL" : "% TOTAL",
    "PERCENTAGE_TOTAL_YTD" : "% TOTAL",
    "PLUS_FIVE_DAYS" : "+5 DAYS",
    "PERCENTAGE_PLUS_DAYS" : "% +5 DAYS",
    "AVG_DAYS" : "AVG DAYS",
    "UNITS_YTD" : "UNITS",
    "UNITS" : "UNITS",
    "C_YTD" : "YTD",
    "T_YTD" : "YTD",
    "SYTD" : "YTD",
    "CYTD" : "YTD",
    "CMTD" : "MTD",
    "SMTD" : "MTD",
    "SERIES_NAME" : "SERIES",
    "REGION" : " ",
    "REGION_NAME" : " ",
    "CHANGE_PRIOR_YEAR_MTD" : "% CHANGE PRIOR YEAR MTD",
    "CHANGE_PRIOR_YEAR_YTD" : "% CHANGE PRIOR YEAR YTD",
    "SALES_PER_OUTLET" : "SALES PER OUTLET",
    "LEXUS_DEALER_PT" : "LEXUS DEALER PENETRATION",
    "LEXUS_POINT" : "LEXUS %",
    "DEALER_DIFF" : "+/- %",
    "NATIONAL_DIFF" : "+/- %",
    "DISTRICT_DIFF" : "+/- %",
    "REGION_DIFF" : "+/- %",
    "DLR_CY" : "YTD",
    "GUIDES":"GUIDE",
    "DIST_CY" : "YTD",
    "RGN_CY" : "YTD",
    "NTNL_CY" : "YTD",
    "DIST_PY" : "Prior YTD",
    "DLR_PY" : "Prior YTD",
    "RGN_PY" : "Prior YTD",
    "NTNL_PY" : "Prior YTD",
    "CMVALUE" : "SELECTED MONTH",
    "TMSGUIDE" : "TMS GUIDE",
    "NCMGUIDE" : "NCM GUIDE",
    "CLASSAVG" : "CLASS AVG",
    "TITLE": " ",
    "YTD_OPEN_CASE_CNT": "CASEOPEN",
    "YTD_OVERDUE_CNT":"COUNT",
    "YTD_OVERDUE_PCT":"%",
    "YTD_NO_RESP_CNT":"COUNT",
    "YTD_NO_RESP_PCT":"%",
    "YTD_CLOSE_EXT_CNT":"COUNT",
    "YTD_CLOSE_EXT_PCT":"%",
    "YTD_CLOSE_DECLINED_CNT":"CLOSEDDECLINED",
    "PROGRAM1":"PROGRAM",
    "PROVIDER1":"PROVIDER",
    "PROGRAM2":"PROGRAM",
    "PROVIDER2":"PROVIDER",
    "PROGRAM3":"PROGRAM",
    "PROVIDER3":"PROVIDER",
    "PROGRAM4":"PROGRAM",
    "PROVIDER4":"PROVIDER",
    "MTD_OBJ":"MTD OBJ",
    "MTD_OBJ_PECT":"%MTD OBJ",
    "SALES_AS_AREA":"SALES AS %AREA",
    "CATEGORY":" ",
    "MTD_DLR":"DLR",
    "MTD_DIST":"DIST AVG",
    "MTD_AREA":"AREA AVG",
    "YTD_DLR":"DLR",
    "YTD_DIST":"DIST AVG",
    "YTD_AREA":"AREA AVG",
    "P_YTDCHANGE_DLR":"DLR",
    "P_YTDCHANGE_DIST":"METRO",
    "P_YTDCHANGE_AREA":"AREA",
    "LEVEL_DISPLAY_NM":" ",
    "MTD_AI_DLR":"DLR",
    "MTD_AI_DIST":"DIST AVG",
    "MTD_AI_AREA":"AREA AVG",
    "YTD_AI_DLR":"DLR",
    "YTD_AI_DIST":"DIST AVG",
    "YTD_AI_AREA":"AREA AVG",
    "LSAAG_LEVEL_DISPLAY_NM":" ",
    "LSAAG_MTD_DLR":"DLR",
    "LSAAG_MTD_DIST":"METRO",
    "LSAAG_MTD_AREA":"AREA",
    "LSAAG_YTD_DLR":"DLR",
    "LSAAG_YTD_DIST":"METRO",
    "LSAAG_YTD_AREA":"AREA",
    "LSAAG_MTD_AI_DLR":"DLR",
    "LSAAG_MTD_AI_DIST":"DIST AVG",
    "LSAAG_MTD_AI_AREA":"AREA AVG",
    "LSAAG_YTD_AI_DLR":"DLR",
    "LSAAG_YTD_AI_DIST":"DIST AVG",
    "LSAAG_YTD_AI_AREA":"AREA AVG",
    "LSAAG_CATEGORY":" ",
    "LSAAG_P_YTDCHANGE_DLR":"DLR",
    "LSAAG_P_YTDCHANGE_DIST":"METRO",
    "LSAAG_P_YTDCHANGE_AREA":"AREA",
    "LSAAG_SALESEFF":" ",
    "LSAAG_MKT_LDR":" ",
    "LSAAG_UNITS":" ",
    "CPO_NEW" : "CPO:NEW",
    "NVS_PERCENT": "NVS %",
    "TOTAL_PO": "TTL PO",
    "TOTAL_PO_NEW" : "TTL PO:NEW"
   }

   export const LexusDealerAtGlanceReport = {
    lexusDAAGCompleteMaintenHeader :[{
    "Lexus Complete Maintenance Care (LCMC)" : "1",
    "Dealer 6-Month History	Month to Date (Average Dlr)":"6",
    "Month to Date (Average Dlr)":"4"
  }],
  }
  