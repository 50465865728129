import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dd-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerComponent implements OnInit {
  @Input() set defaultDate(date: Date) {
    this.dateValue = date;
  }
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() datePickerFilter: () => boolean;
  @Input() disableInput = false;
  @Input() readOnly = false;
  @Input() disableDatePicker = false;
  @Input() rdr = false;

  @Output() dateInput: EventEmitter<Event> = new EventEmitter();
  @Output() dateChange: EventEmitter<Event> = new EventEmitter();
  @ViewChild('picker', { static: false }) datePicker: MatDatepicker<Date>;

  public dateValue: Date = null;
  public lexusClass = '';


  readonly subscription: Subscription = new Subscription();

  constructor() { }

  public ngOnInit() { }

  public openCalendar() {
    /* istanbul ignore else */
    if (this.readOnly) {
      this.datePicker.open();
    }
  }
  public handleInputChange($event) {
    this.dateValue = $event.value;
    this.dateChange.emit($event);
  }
  public handleInput($event) {
    this.dateValue = $event.value;
    this.dateInput.emit($event);
  }
  public open() {
    this.datePicker.open();
  }
  public close() {
    this.datePicker.close();
  }
  public isOpen() {
    return this.datePicker.opened;
  }

  public setDate(date: Date) {
    this.datePicker.select(date);
  }

}
