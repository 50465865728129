import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
  })
 
export class ToastService{
constructor(public snackbar: MatSnackBar){}

 public openSnackBar(message:string, action , duration :number, cssClass:string){
        this.snackbar.open(message,action,{
            duration,panelClass:[cssClass]
        })
    }

 public openSnackBarFail(message:string,action="X",duration= 5000){
        this.openSnackBar(message,action,duration,'snackbar-fail');
    }
 
}